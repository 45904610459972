import React, {useEffect} from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ChannelType } from "../../../models/Channel";
import {
  selectSelectedLocation,
  selectAdjustedSpendPercent,
  selectAlwaysOn,
  selectAlwaysOnEstimatedBudget,
  selectCurrentEstimatedCost,
  selectSelectedChannels,
  selectSelectedCustomPackage,
  selectTotalAudience,
  selectWeeklySpendEstimate,
  selectZipModeEnabled,
  selectPrimaryZips,
  selectSecondaryZips,
  selectIsBudgetModeFixed,
  selectMonthlyBudget,
  setSelectedMonth,
  selectedMonth,
} from "../../../store/campaignForm/CampaignForm";
import ChannelSidebarCard from "./ChannelSidebarCard";
import "./styles.scss";
import { Chart } from "primereact/chart";
import { CustomPackage } from "../../../models/CustomPackage";
import PackageSidebarCard from "./PackageSidebarCard";
import { getMoneyStringFromNumber } from "../../../utility/string";
import { getMonthIndexByCode, MonthlyBudgetOptions} from "../../../models/MonthlyBudget"

const ChannelSidebar = () => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectSelectedLocation);
  const selectedChannels: ChannelType[] = useSelector(selectSelectedChannels);
  const selectedPackage: CustomPackage | null = useSelector(
    selectSelectedCustomPackage
  );
  const estimatedCost = useSelector(selectCurrentEstimatedCost);
  const adjustedSpendPercent = useSelector(selectAdjustedSpendPercent);
  const alwaysOnBudget = useSelector(selectAlwaysOnEstimatedBudget);
  const weeklySpend: number = useSelector(selectWeeklySpendEstimate);
  const targetZoneAudience: number = useSelector(selectTotalAudience);
  const alwaysOn: boolean = useSelector(selectAlwaysOn);
  const isFixedBudgetMode = useSelector(selectIsBudgetModeFixed);
  const monthlyBudget = useSelector(selectMonthlyBudget) as number[];
  const currentMonthCode = useSelector(selectedMonth);

  const percentSpend = estimatedCost * (adjustedSpendPercent / 100)

  const usesZipMode = useSelector(selectZipModeEnabled);
  const selectedPrimaryZips = useSelector(selectPrimaryZips);
  const selectedSecondaryZips = useSelector(selectSecondaryZips);

  const chartData = {
    labels: selectedChannels.map((channel) => channel.title),
    datasets: [
      {
        data: selectedChannels.map(
          (channel) => channel.investmentInDollarsPerTarget
        ),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#02C39A",
          "#8E98F5",
          "#0081C6",
          "#FF9D9D",
          "#EBD671",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#02C39A",
          "#8E98F5",
          "#0081C6",
          "#FF9D9D",
          "#EBD671",
        ],
      },
    ],
  };

  const lightOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `${context.label}: ${getPorcentaje(context)}%`;
          },
        },
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  };

  const getPorcentaje = (channel: any) => {
    let totalValue = 0;
    selectedChannels.forEach((c) => {
      if (c.investmentInDollarsPerTarget)
        totalValue = totalValue + +c.investmentInDollarsPerTarget;
    });
    return (
      (channel.formattedValue.replace(/,/g, ".") * 100) /
      totalValue
    ).toFixed(2);
  };

  const handleMonthChange = (value: string) => {
    dispatch(setSelectedMonth(value));
  }

  useEffect(() => {
    if (isFixedBudgetMode) {
      dispatch(setSelectedMonth(
          currentMonthCode && currentMonthCode.length > 0 ? currentMonthCode : MonthlyBudgetOptions()[0].code)
      );
    }
  }, []);

  const renderCards = (channel: ChannelType) => {
    return (
      <ChannelSidebarCard
        key={`selected channel in sidebar: ${channel.title}`}
        channel={channel}
      />
    );
  };

  // const isActive = (channelTitle: string) => {
  //   for (let channel of selectedChannels) {
  //     if (channel.title === channelTitle) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  return (
    <div>
      <div>

        <div className="target-container res:hidden">
          <span className="target-title">Buying For:</span>
          <span>{selectedLocation?.name}</span>
        </div>
        
        { usesZipMode
          ?

            <div className="target-container">
              <div className="target-title">Audience Size:</div>

              { ( selectedPrimaryZips.length + selectedSecondaryZips.length ).toString() + ' zips' }
            </div>

          :
            ( !!targetZoneAudience && (
              <div className="target-container">
                <div className="target-title">Audience Size:</div>

                {targetZoneAudience.toLocaleString()}
              </div>
            ))
        }

        {selectedChannels.length > 0 && (
            <>
              {
                isFixedBudgetMode ?
                    <div className="target-container">
                    <Select style={{ width: "100%" }} defaultValue={ currentMonthCode } onChange={handleMonthChange}>
                      {
                        MonthlyBudgetOptions().map((month, index) => {
                          return (
                              <Select.Option
                                  key={index}
                                  value={month.code}
                              >{month.label} - ${monthlyBudget[getMonthIndexByCode(month.code)]}</Select.Option>
                          )
                        })
                      }
                    </Select>
                    </div>
                    :
                    <div className="target-container">
                      <div className="target-title">Budget:</div>
                      ${getMoneyStringFromNumber(estimatedCost)}
                    </div>
              }
            </>
        )}

      </div>
      <div className="sidebar-container res:pb-0">
        {selectedChannels.length !== 0 || selectedPackage !== null ? (
            <div className="channel-sidebar-container">
            {selectedChannels.map(renderCards)}
            {selectedPackage && (
              <PackageSidebarCard customPackage={selectedPackage} />
            )}
          </div>
        ) : (
          <div className="sidebar-location-select-text">

          </div>
        )}
        {selectedChannels.length >= 2 && (
          <div className="sidebar-spend-by-container res:hidden">
            <div className="sidebar-spend-by-title">Spend by Channel</div>
            <div
              className="card flex justify-content-center"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Chart
                type="doughnut"
                data={chartData}
                options={lightOptions}
                style={{ position: "relative", width: "90%" }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ChannelSidebar);
