import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconGrid } from "../../assets/icons/grid.svg";
import "./styles.css";
import LocationSideBar from "../purchase/locations/sidebar";
import { AvailableRoutes } from "../../pages/Routes";
import { ReactComponent as IconLocationStep } from "../../assets/icons/location-step.svg";
import { ReactComponent as IconLocationStep1 } from "../../assets/icons/step1location.svg";
import { ReactComponent as IconChannelStep2 } from "../../assets/icons/step2campaignType.svg";
import { ReactComponent as IconStepSeparator } from "../../assets/icons/stepSeparator.svg";
import { ReactComponent as IconStep2Counterpart } from "../../assets/icons/step2counterpart.svg";
import { ReactComponent as IconScheduleStep3 } from "../../assets/icons/step3schedule.svg";
import { ReactComponent as IconStep3Counterpart } from "../../assets/icons/step3counterpart.svg";
import { ReactComponent as IconReviewStep4 } from "../../assets/icons/step4review.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel.svg";
import { useDispatch, useSelector } from "react-redux";
import AccountSelection from "../purchase/locations/AccountSelection";
import { Button } from "antd";
import {
  saveTargetZone,
  selectAgreedToTerms,
  selectAlwaysOn,
  selectAdjustedSpendPercent,
  selectCurrentEstimatedCost,
  selectNewCampaignChannels,
  selectNewCampaignCustomPackage,
  selectPaymentMethod,
  selectSavedTargetZones,
  selectSelectedLocation,
  selectTemplateTitle,
  selectTotalArea,
  selectTotalAudience,
  setIsZoneSelected,
  selectZipModeEnabled,
  setZipModeEnabled,
  selectPrimaryZips,
  selectSecondaryZips,
  selectSelectedLocationPrimaryZips,
  selectSelectedLocationSecondaryZips,
  selectIsBudgetModeFixed,
  selectedMonth,
  selectAudienceSegment,
} from "../../store/campaignForm/CampaignForm";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import HeaderLayout from "./HeaderLayout";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { createAdCampaign } from "../../store/AdCampaigns/AdCampaignStore";
import { Account } from "../../models/Account";
import { ReactComponent as IconMore } from "../../assets/icons/more-vertical.svg";
import ScheduleSidebar from "../purchase/schedule/ScheduleSidebar";
import ReviewAndPaySidebar from "../purchase/reviewAndPay/ReviewAndPaySidebar";
import { CountryEnum } from "../../models/Location";
import { getMoneyStringFromNumber } from "../../utility/string";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down-orange.svg";
import services from "../../services/index"
import { selectUserAccount } from "../../store/Account/AccountStore"
import { selectFranchiseLocations} from "../../store/Location/LocationStore";
import { selectFranchise } from "../../store/Franchise/FranchiseStore";

const AddCampaignFlowLayout = ({ children }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const templateTitle: string = useSelector(selectTemplateTitle);
  const selectedLocation = useSelector(selectSelectedLocation);
  const savedZones = useSelector(selectSavedTargetZones);
  const audience = useSelector(selectTotalAudience);
  const area = useSelector(selectTotalArea);
  const alwaysOn = useSelector(selectAlwaysOn);
  const adjustedSpendPercent = useSelector(selectAdjustedSpendPercent);
  const acceptedTerms = useSelector(selectAgreedToTerms);
  const paymentMethod = useSelector(selectPaymentMethod);
  const newChannels = useSelector(selectNewCampaignChannels);
  const newPackage = useSelector(selectNewCampaignCustomPackage);
  const estimatedCost = useSelector(selectCurrentEstimatedCost);
  const paymentId = useSelector(selectPaymentMethod);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const whiteLabel = useSelector(selectWhiteLabel);
  const account = useSelector(selectUserAccount);
  const locations = useSelector(selectFranchiseLocations);
  const franchise = useSelector(selectFranchise);
  const usesZipMode = useSelector(selectZipModeEnabled);
  const selectedPrimaryZips = useSelector(selectPrimaryZips)
  const selectedSecondaryZips = useSelector(selectSecondaryZips)
  const locationPrimaryZips = useSelector(selectSelectedLocationPrimaryZips);
  const locationSecondaryZips = useSelector(selectSelectedLocationSecondaryZips);
  const audienceSegment = useSelector(selectAudienceSegment);

  useEffect(() => {
    services.dataLayerService.registerPageView();
  });
  const isFixedBudgetMode = useSelector(selectIsBudgetModeFixed);
  const budgetMonthCode = useSelector(selectedMonth);

  const renderHeader = () => {
    switch (location.pathname) {
      case AvailableRoutes.TARGET_ZONE:
        return (
          <div className="stepper">
            <div className="stepper-icons res:!w-60 res:!min-w-0 res:!scale-100">
              <IconLocationStep1
                onClick={() => navigate(AvailableRoutes.LOCATIONS)}
              />
              <IconStepSeparator />
              <IconChannelStep2 />
              <IconStep2Counterpart />
            </div>
          </div>
        );
      case AvailableRoutes.CHANNELS:
        return (
          <div className="stepper">
            <div className="stepper-icons  res:!w-60 res:!min-w-0 res:!scale-100">
              <IconLocationStep1
                onClick={() => navigate(AvailableRoutes.LOCATIONS)}
              />
              <IconStepSeparator />
              <IconChannelStep2 />
              <IconStep2Counterpart />
            </div>
          </div>
        );
      case AvailableRoutes.TEMPLATES:
        return;
      case AvailableRoutes.SCHEDULE:
        return (
          <div className="stepper">
            <div className="stepper-icons res:!w-60 res:!min-w-0 res:!scale-100">
              <IconLocationStep1
                onClick={() => navigate(AvailableRoutes.LOCATIONS)}
              />
              <IconStepSeparator />
              <IconChannelStep2
                onClick={() => navigate(AvailableRoutes.CHANNELS)}
              />
              <IconStepSeparator />
              <IconScheduleStep3 />
              <IconStep3Counterpart />
            </div>
          </div>
        );
      case AvailableRoutes.REVIEW:
        return (
          <div className="stepper">
            <div className="stepper-icons res:!w-60 res:!min-w-0 res:!scale-100">
              <IconLocationStep1
                onClick={() => navigate(AvailableRoutes.LOCATIONS)}
              />
              <IconStepSeparator />
              <IconChannelStep2
                onClick={() => navigate(AvailableRoutes.CHANNELS)}
              />
              <IconStepSeparator />
              <IconScheduleStep3
                onClick={() => navigate(AvailableRoutes.SCHEDULE)}
              />
              <IconStepSeparator />
              <IconReviewStep4 />
            </div>
          </div>
        );
      case AvailableRoutes.LOCATIONS:
      default:
        return (
          <div className="stepper">
            <div className="stepper-icons res:!min-w-0 res:!scale-100">
              <IconLocationStep className=" res:!w-60 res:!h-16" />
            </div>
          </div>
        );
    }
  };

  const renderHeaderTitle = () => {
    switch (location.pathname) {
      case AvailableRoutes.TARGET_ZONE:
        return <div className="step-title res:text-left">Define your audience</div>;
      case AvailableRoutes.CHANNELS:
        return <div className="step-title res:text-left">Select campaign type</div>;
      case AvailableRoutes.TEMPLATES:
        return (
          <div
            style={{
              fontFamily: "Mulish",
              fontSize: "20px",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            Available Templates for: {templateTitle}
            <CancelIcon onClick={() => navigate(AvailableRoutes.CHANNELS)} />
          </div>
        );
      case AvailableRoutes.SCHEDULE:
        return <div className="step-title text-left">Set a schedule</div>;
      case AvailableRoutes.REVIEW:
        return <div className="step-title res:text-left">Review & pay</div>;
      case AvailableRoutes.LOCATIONS:
      default:
        return <div className="step-title res:text-left">Select a location</div>;
    }
  };

  const renderNextButtonTitle = (): string => {
    switch (location.pathname) {
      case AvailableRoutes.TARGET_ZONE:
        return "CHOOSE CAMPAIGN TYPE";
      case AvailableRoutes.TEMPLATES:
      case AvailableRoutes.CHANNELS:
        return "SCHEDULE";
      case AvailableRoutes.SCHEDULE:
        return "REVIEW & PAY";
      case AvailableRoutes.REVIEW:
        return "PLACE ORDER";
      case AvailableRoutes.LOCATIONS:
      default:
        return "DEFINE YOUR AUDIENCE";
    }
  };

  const handleNext = (): void => {
    switch (location.pathname) {
      case AvailableRoutes.TARGET_ZONE:
        if (savedZones.length > 0) navigate(AvailableRoutes.CHANNELS);
        else dispatch(setIsZoneSelected(false));
        break;
      case AvailableRoutes.CHANNELS:
        navigate(AvailableRoutes.SCHEDULE);
        break;
      case AvailableRoutes.SCHEDULE:
        navigate(AvailableRoutes.REVIEW);
        break;
      case AvailableRoutes.REVIEW:
        if(
          selectedLocation !== null &&
          acceptedTerms &&
          paymentMethod !== "" &&
          paymentMethod !== "newCard"
        ){

          if( usesZipMode){
            //Request the campaign creation using ZIP codes.
            //This will ignore audienceSize, aureaInMiles and zones.
            if (
              selectedPrimaryZips.length > 0 ||
              selectedSecondaryZips.length > 0
            ) {
              const combinedZips = selectedPrimaryZips.concat(selectedSecondaryZips);
              dispatch(
                createAdCampaign({
                  account: { id: "acctId" } as Account,
                  newCampaignChannels: newChannels,
                  newPackages: newPackage ? [newPackage] : [],
                  audienceSize: 0,
                  areaInMiles: 0,
                  locationIds: [selectedLocation.id],
                  costInDollars: Number(isFixedBudgetMode ? 0 : estimatedCost.toFixed(2)),
                  cardId: paymentId,
                  zones: [],
                  adjustedSpendPercent: adjustedSpendPercent / 100,
                  alwaysOn: alwaysOn,
                  zips: combinedZips,
                  month: budgetMonthCode,
                  segments: [],
                })
              );
            }
          }else{
            //Request the campaign creation using "draft" or zones.
            //This will ignore the primary and secondary zips.
            if (
              audience > 0 &&
              area !== null
            ) {
              dispatch(
                createAdCampaign({
                  account: { id: "acctId" } as Account,
                  newCampaignChannels: newChannels,
                  newPackages: newPackage ? [newPackage] : [],
                  audienceSize: audience,
                  areaInMiles: area,
                  locationIds: [selectedLocation.id],
                  costInDollars: Number(isFixedBudgetMode ? 0 : estimatedCost.toFixed(2)),
                  cardId: paymentId,
                  zones: savedZones,
                  adjustedSpendPercent: adjustedSpendPercent / 100,
                  alwaysOn: alwaysOn,
                  zips: [],
                  month: budgetMonthCode,
                  segments: audienceSegment
                })
              );
            }
          }
        }
        break;
      case AvailableRoutes.LOCATIONS:
      default:
        if (selectedLocation !== null) {
          if (selectedLocation.country !== CountryEnum.USA) {
            dispatch(
              saveTargetZone({
                area: 0,
                audience: 0,
                center: { lat: 0, lng: 0 },
                radiusInMiles: 0,
                //selectors: []
              })
            );
            navigate(AvailableRoutes.CHANNELS);
          } else {
            navigate(AvailableRoutes.TARGET_ZONE);
          }
        }
        break;
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <HeaderLayout>
        <div style={{ display: "flex", justifyContent: "space-between" }} className="icon-grid">
          <IconGrid
            style={{width: '30px' }}
            className="res:!m-[auto_25px] res:!w-6 m-[auto_50px] ml:margin[auto_50px_auto_50px]"
            onClick={() => {
              navigate("/");
            }}
          />
          {renderHeader()}
        </div>
      </HeaderLayout>

      <div className="content-body">
        <div className="content-body-content res:pb-80">
          <div className="flex justify-between items-center">
            {renderHeaderTitle()}
            {(location.pathname === AvailableRoutes.TARGET_ZONE) && (
              <div className="flex gap-x-4">

                {( locationPrimaryZips.length>0 || locationSecondaryZips.length>0 ) && (
                  <Button
                    className={`${!usesZipMode? 'opacity-[0.6]' : ''} bg-white border-[1px] w-28 cursor-pointer border-[--color-primary] font-bold text-[--color-primary] border-solid rounded-[4px] text-center h-14 `}
                    onClick={() => {
                      dispatch(setZipModeEnabled(true))
                    }}
                  >
                    MY GEOS
                  </Button>
                )}

                {( locationPrimaryZips.length>0 || locationSecondaryZips.length>0 ) && (
                <Button
                  className={`${usesZipMode ? 'opacity-[0.6]' : ''} bg-white border-[1px] w-28 cursor-pointer border-[--color-primary] font-bold text-[--color-primary] border-solid rounded-[4px] text-center h-14`}
                  onClick={() => {
                    dispatch(setZipModeEnabled(false))
                  }}
                >
                  DRAW
                </Button>
                )}

              </div>
            )}

          </div>
          {children}
        </div>
        <LocationSideBar />

        {(location.pathname !== AvailableRoutes.TARGET_ZONE &&
          location.pathname !== AvailableRoutes.CHANNELS)
          && (
            <div className="mobile-sidebar-button res:bg-white res:p-6 res:w-full res:max-h-80 res:overflow-y-scroll">
              {( location.pathname === AvailableRoutes.LOCATIONS) && (
                <div className=" res:mb-4">
                  <AccountSelection />
                </div>
              )}
              <div className="mobile-button-background res:!p-0 res:w-full res:flex-col res:!bg-white">
                {(location.pathname === AvailableRoutes.SCHEDULE && showOrderDetails) && (
                  <div>
                    <ScheduleSidebar />
                  </div>
                )}
                {(
                    location.pathname === AvailableRoutes.SCHEDULE
                    ) && (
                      <div className="flex justify-center bg-white mb-4">
                        <p 
                          className="text-black text-center text-lg res:flex items-center justify-center mb-0"
                          onClick={()=> { setShowOrderDetails(!showOrderDetails)} }
                        >
                          Estimated budget: ${getMoneyStringFromNumber(estimatedCost)}
                          { showOrderDetails && (<ArrowIcon className="res:ml-3 svg-color-primary cursor-pointer" />) }
                          { !showOrderDetails && (<ArrowIcon className="res:ml-3 svg-color-primary" style={{ cursor: "pointer", transform: "rotate(180deg)" }} />) }
                        </p>
                      </div>
                      
                  )}
                {(location.pathname === AvailableRoutes.REVIEW && (
                  <ReviewAndPaySidebar />
                ))}
                <div
                  className="sidebar-dashboard-button pointer-hover res:w-full"
                  onClick={() => {
                    setShowMobileSidebar(false);
                    console.log('nmexxxt')
                    handleNext();
                  }}
                >
                  <div className="sidebar-dashboard-button-text ">
                    {renderNextButtonTitle()}
                  </div>
                  <IconArrowRight />
                </div>
                {(
                  location.pathname === AvailableRoutes.SCHEDULE ||
                  location.pathname === AvailableRoutes.REVIEW) && (
                    <IconMore
                      className="mobile-more-icon res:hidden"
                      onClick={() => setShowMobileSidebar(!showMobileSidebar)}
                    />
                  )}
                  
                  
              </div>
              
            </div>
          )}

        {showMobileSidebar && (
          <div className="mobile-sidebar-container">
            <div className="mobile-sidebar-oppacity" onClick={() => setShowMobileSidebar(false)}></div>
            <div className="mobile-sidebar">
              {location.pathname === AvailableRoutes.SCHEDULE && (
                <ScheduleSidebar />
              )}
              {location.pathname === AvailableRoutes.REVIEW && (
                <ReviewAndPaySidebar />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AddCampaignFlowLayout);
