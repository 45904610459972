import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NewCampaignChannel } from "../../../models/CampaignChannel";
import { NewCampaignCustomPackage } from "../../../models/CampaignPackage";
import { ChannelType } from "../../../models/Channel";
import { CreativeTemplate } from "../../../models/CreativeTemplate";
import { CustomPackage } from "../../../models/CustomPackage";
import {
  selectNewCampaignChannels,
  setNewCampaignCustomPackage,
  selectSelectedChannels,
  selectSelectedCustomPackage,
  setNewCampaignChannels,
  selectNewCampaignCustomPackage,
  selectSelectedTemplates,
  selectIsBudgetModeFixed,
  selectedMonth
} from "../../../store/campaignForm/CampaignForm";
import { addDaysToDate, HOURS_IN_DAY } from "../../../utility/time";
import { EventCalendar } from "../../eventCalendar/EventCalendar";
import "./ScheduleCampaign.scss";
import ChannelCard from "./ChannelCard";
import PackageCard from "./PackageCard";
import { getMonthIndexByCode, getMonthLastDate } from "../../../models/MonthlyBudget"

const ScheduleCampaign = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const dispatch = useDispatch();
  const selectedChannels: ChannelType[] = useSelector(selectSelectedChannels);
  const selectedTemplates: CreativeTemplate[] = useSelector(selectSelectedTemplates);
  const selectedPackage: CustomPackage | null = useSelector(selectSelectedCustomPackage);
  const newCampaignChannels: NewCampaignChannel[] = useSelector(selectNewCampaignChannels);
  const newCampaignPackage: NewCampaignCustomPackage | null = useSelector(selectNewCampaignCustomPackage);
  const [minimumStart, setMinimumStart] = useState<Date>(new Date(today.getTime()));
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(today.getTime()));
  const [dateLoading, setDateLoading] = useState<Boolean>(true);
  const isFixedMonthlyBudget = useSelector(selectIsBudgetModeFixed);
  const campaignMonthlyBudgetCode = useSelector(selectedMonth);

  /**
   * Detect fixed budget start date
   */
  const getFixedBudgetStartDate = () => {
    const monthIndex = getMonthIndexByCode(campaignMonthlyBudgetCode);
    const currentMonth = (new Date()).getMonth();
    const currentYear = (new Date()).getFullYear();

    let startDate = addDaysToDate(today, 14);

    if (monthIndex > currentMonth) {
      const startDateCandidate = new Date(currentYear, monthIndex, 1);

      return startDate > startDateCandidate ? startDate : startDateCandidate;
    }

    if (monthIndex === currentMonth) {
      const startDateCandidate = getMonthLastDate(monthIndex);
      startDateCandidate.setDate(0);

      return startDate > startDateCandidate ? startDateCandidate : startDate;
    }

    throw new Error('Could not identify campaign start date');
  }

  useEffect(() => {

    const monthIndex = getMonthIndexByCode(campaignMonthlyBudgetCode);

    // minimum start date is one week past current date
    let minimumStartDate = addDaysToDate(today, 7);

    //* if not a weekday, go to next monday
    if (minimumStartDate.getDay() === 0) {
      minimumStartDate = addDaysToDate(minimumStartDate, 1);
    } 
    else if (minimumStartDate.getDay() === 6) {
      minimumStartDate = addDaysToDate(minimumStartDate, 2);
    }
    // else if (minimumStartDate.getDay() > 1) {
    //   minimumStartDate = addDaysToDate(
    //     minimumStartDate,
    //     8 - minimumStartDate.getDay()
    //   );
    // }

    if (isFixedMonthlyBudget) {
      minimumStartDate = getFixedBudgetStartDate();
    }

    setMinimumStart(new Date(minimumStartDate.getTime()));
    setSelectedDate(new Date(minimumStartDate.getTime()))
    setDateLoading(false);

    if (selectedChannels.length > 0) {
      const newCChannels = selectedChannels.map(
        (channel): NewCampaignChannel => {
          const templateForChannel = selectedTemplates.find(
            (template) => template.channelId === channel.id
          );
          const start = new Date(minimumStartDate.getTime());
          const newCampaignChannel = {
            startDate: start,
            endDate: addDaysToDate(
                start,
                channel.durationHours / HOURS_IN_DAY
            ),
            channelType: channel,
            readyToDisplay: false,
            creativeId: templateForChannel?.displayName, //use display name instead of the id now.
          };

          if (isFixedMonthlyBudget) {
            newCampaignChannel.endDate = getMonthLastDate(monthIndex);
          }

          if (newCampaignChannels.length > 0) {
            const oldCampaignChannel = newCampaignChannels.filter((campaignChannel) => {
              return campaignChannel.channelType.id === channel.id;
            });
             return Object.assign(oldCampaignChannel, newCampaignChannel);
          }
          return newCampaignChannel;
        }
      );
      dispatch(setNewCampaignChannels(newCChannels));
      dispatch(setNewCampaignCustomPackage(null));
    } else if (selectedPackage !== null) {
      const start = new Date(minimumStartDate.getTime());
      const newCampaignCustomPackage: NewCampaignCustomPackage = {
        customPackage: selectedPackage,
        startDate: start,
        endDate: addDaysToDate(
          start,
          selectedPackage.standardDurationInHours / HOURS_IN_DAY
        ),
        readyToDisplay: false,
      };
      dispatch(setNewCampaignCustomPackage(newCampaignCustomPackage));
      dispatch(setNewCampaignChannels([]));
    } else {
      dispatch(setNewCampaignChannels([]));
      dispatch(setNewCampaignCustomPackage(null));
      setMinimumStart(today);
    }
  }, [selectedChannels, selectedPackage]);

  //Changes the current selected date
  const changeSelectedDate = (newDate: Date) => {
    setSelectedDate(newDate);
  }

  return (
    <div className={classNames.container}>
      {newCampaignPackage && <PackageCard onSelectedDateChange={changeSelectedDate} minimumStart={minimumStart} campaignPackage={newCampaignPackage} />}
      {newCampaignChannels.map((channel, index) => (
        <ChannelCard key={index} onSelectedDateChange={changeSelectedDate} minimumStart={minimumStart} campaignChannel={channel} />
      ))}
      {!newCampaignPackage && newCampaignChannels.length === 0 && (
        <div className={classNames.channelCard}>No Channel Selected</div>
      )}
      <div className={classNames.calendar}>
        { dateLoading == false && 
          <EventCalendar defaultStartDate={selectedDate} />
        }
      </div>
    </div>
  );
};

const classNames = {
  container: "schedule-campaign-container",
  calendar: "schedule-campaign-calendar-container schedule-calendar mobile-calendar",
  channelCard: "channel-card",
};

export default ScheduleCampaign;
