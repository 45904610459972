import { Select } from "antd";
import { getSubdomain } from "../../../utility/subdomain";

const { Option } = Select;
const allSegments = [
    <Option key={1} value="M">Male</Option>,
    <Option key={2} value="F">Female</Option>,
    <Option key={3} value="age21_39">Age 21-39</Option>,
    <Option key={4} value="age40_49">Age 40-49</Option>,
    <Option key={5} value="age50_80">Age 50-80</Option>,
    <Option key={6} value="age80">Age 80+</Option>,
    <Option key={7} value="income60">Income -60k</Option>,
    <Option key={8} value="income60_100">Income 60k-100k</Option>,
    <Option key={9} value="income100">Income 100k+</Option>
]

const expproSegments = [
    <Option key={1} value="age21_39">Light Industrial</Option>,
    <Option key={2} value="age40_49">Skilled Trades</Option>,
    <Option key={3} value="age50_80">Professional</Option>,
    <Option key={4} value="income60">Office Services</Option>,
    <Option key={5} value="income60_100">Construction</Option>,
    <Option key={6} value="income100">Receptionists and Banking</Option>,
] 

const msqsqdSegments = [
    <Option key={1} value="M">Homeowners age 35+</Option>,
    <Option key={2} value="F">Homes with pets</Option>,
    <Option key={3} value="age21_39">Homes with pools</Option>,
    <Option key={4} value="income100">Income 500k+</Option>,
    <Option key={5} value="age50_80">Vehicle owner: Acura</Option>,
    <Option key={6} value="age80">Vehicle owner: BMW</Option>,
    <Option key={7} value="income60_100">Social media follower: News</Option>,
    <Option key={8} value="income60">Social media follower: Political</Option>
]

const subDomain = getSubdomain()
let segments: any[]
switch (subDomain){
    case "www":
    case "demo":
        segments = allSegments
        break
    case "exppro":
        segments = expproSegments
        break  
    case "hotdish":
        segments = allSegments
        break
    case "mosquitosquad":
        segments = msqsqdSegments
        break
    default:
        segments = allSegments
}

export {segments};
