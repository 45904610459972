import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Budget, MonthlyBudgets } from "../../models/Budget";
import services from "../../services";
import { INewLocation, IUpdateLocation, LocationFadsSchema, LocationGadsKeyWords, LocationGadsSchema, LocationRowSchema, LocationCustomChannelSchema } from "../../services/locationService";
import { RootState } from "../index";

import { BudgetRowSchema } from "../../services/BudgetService";

export const fetchMonthlyBudgetsList = createAsyncThunk(
  "budgets/monthly",
  async () => {
    console.log('fetching monthly budgets...');
    return services.budgetService.getMonthlyBudgetsList();
  }
);


//Creates multiple locations from a csv or xlsx file
// @params
// locationSchemaArray (An array of LocationRowSchema objects representing the rows in the file)
export const importBudgets = createAsyncThunk<
  Budget[], 
  {
    budgetSchemaArray: BudgetRowSchema[]
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "budgets/import",
  async (budgetsSchemaArray, { rejectWithValue }) => {
    try {
      let result = await services.budgetService.importBudgets(budgetsSchemaArray.budgetSchemaArray);
      return result;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      //This is the validation error returned by the Platform API using the tsoa library.
      if (errorData.message == "Validation Failed") {
        //This returns a "details" object which contains both the row number and attribute, as well as 
        //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
        let details = errorData.details;
        Object.keys(details).forEach(key => {
          //The key contains both the row# and the failed attribute
          //The attribute "message" contains the error message.
          //We attempt to format these in a single string.
          let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
          let colName = key.substring(key.indexOf(".") + 4);
          let rowNumberInt = parseInt(rowNumber) + 1;

          let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
          formattedErrors.push(errorMessage);
        })
      } else if (errorData.message == "Entity Not Found") {
        formattedErrors.push(errorData.details);
      } else if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);

    }

  }
);

export const budgetSlice = createSlice({
  name: "budgets",
  initialState: {
    budgetErrors: [] as String[] | null,
    importBudgetsResult: null as String | null,
    list: [] as MonthlyBudgets[],
    loading: true,
  },
  reducers: {
    setBudgetsErrors: (
        state,
        { payload: budgetsErrors }: PayloadAction<String[] | null>
    ) => {
    state.budgetErrors = budgetsErrors;
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(importBudgets.fulfilled, (state, action) => {
      let importedLocations = action.payload;
      state.importBudgetsResult = `Successfully imported ${importedLocations.length} new locations.`;
    });

    builder.addCase(importBudgets.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.budgetErrors = action.payload!;
      }
    });

    builder.addCase(fetchMonthlyBudgetsList.fulfilled, (state, action) => {
      
      state.list = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchMonthlyBudgetsList.rejected, (state, action) => {
      state.loading = false;
    });

  },
});



export const budgetErrors = (state: RootState): String[] | null =>
  state.budgets.budgetErrors;

export const importBudgetsResult = (state: RootState): String | null =>
  state.budgets.importBudgetsResult;

export const selectMonthlyBudgetsList = (state: RootState): MonthlyBudgets[] =>
  state.budgets.list;

export const selectLoading = (state: RootState) : boolean =>
  state.budgets.loading;

export default budgetSlice.reducer;
