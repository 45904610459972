import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../../assets/icons/arrow-right.svg";

import "./styles.css";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdminAvailableRoutes } from "../../../../pages/AdminRoutes"; 
import { AvailableRoutes } from "../../../../pages/Routes";

const AdminDashboardSideBar = ({ children }: any) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("directory");
  const today = new Date();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 18,
      top: 18,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      background: "#FF5566",
    },
  }));

  return (
    <div className="content-body-sidebar sidebar-mobile-body">
      <div className="sidebar-dashboard mobile-hide">
        <Link
          to={"/"}
          target="_blank"
          className="sidebar-dashboard-button pointer-hover"
        >
          <div className="sidebar-dashboard-button-text">PORTAL</div>
          <IconArrowRight />
        </Link>
      </div>
      <div className="sidebar-dashboard-header">
        <div className="sidebar-dashboard-header-menu"> 

            <div onClick={()=>{
              navigate(AdminAvailableRoutes.DIRECTORY)
              setSelectedOption("directory")}} className={"sidebar-dashboard-header-menu-item " + (selectedOption === "directory" ? 'active' : '') }>
              <span>Directory</span>
            </div> 

            <div onClick={ () => {
                navigate(AdminAvailableRoutes.ONBOARDING);
                setSelectedOption("onboarding")}} className={"sidebar-dashboard-header-menu-item " + (selectedOption === "onboarding" ? 'active' : '') }>
              <span>Onboarding</span>
            </div>

           <div onClick={ () => {
                navigate(AdminAvailableRoutes.VERSIONS);
                setSelectedOption("experiences")}} className={"sidebar-dashboard-header-menu-item " + (selectedOption === "experiences" ? 'active' : '') }>
              <span>Experiences</span>
            </div>

{/*            <div onClick={()=>{
              navigate(AdminAvailableRoutes.MEDIA_MANAGER)
              setSelectedOption("mediaManager")}} className={"sidebar-dashboard-header-menu-item " + (selectedOption === "mediaManager" ? 'active' : '') }>
              <span>Media Manager</span>
            </div>
*/}

            <div onClick={()=>{
              navigate(AdminAvailableRoutes.DEMOGRAPHICS)
              setSelectedOption("demographics")}} className={"sidebar-dashboard-header-menu-item " + (selectedOption === "demographics" ? 'active' : '') }>
              <span>Demographics</span>
            </div>

            <div onClick={()=>{
              navigate(AdminAvailableRoutes.PERFORMANCE_IMPORT)
              setSelectedOption("performanceImport")}} className={"sidebar-dashboard-header-menu-item " + (selectedOption === "performanceImport" ? 'active' : '') }>
              <span>Performance import</span>
            </div>

           <div onClick={()=>{
              navigate(AdminAvailableRoutes.BULK_EMAILS)
              setSelectedOption("bulkUpdates")}} className={"sidebar-dashboard-header-menu-item " + (selectedOption === "bulkUpdates" ? 'active' : '') }>
              <span>Bulk updates</span>
            </div>

            <div onClick={()=>{
              navigate(AdminAvailableRoutes.QR_MANAGER)
              setSelectedOption("qrManager")}} className={"sidebar-dashboard-header-menu-item " + (selectedOption === "qrManager" ? 'active' : '') }>
              <span>QR manager</span>
            </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdminDashboardSideBar);
