import { useState, useEffect } from "react";
import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "assets/icons/loader-onmiChannel.svg";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedMainMenu, setSelectedSubMenu } from "../../store/Navigation/NavigationStore";
import { selectFranchiseLocations } from "../../store/Location/LocationStore";
import "./styles.css";
import LocationCard from "../purchase/locations/LocationCard";
import { RadioGroup } from "@mui/material";
import { KeywordSideBard } from "./KeywordSideBar";
import { Modal } from 'antd';
import { selectFranchise } from 'store/Franchise/FranchiseStore'
import { selectWhiteLabel } from "store/WhiteLabel/WhiteLabelStore";

import CustomSwitch from "../Switch/CustomSwitch";
import CustomTooltip from "../tooltip/tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";

export const KeywordHelperPage = (): JSX.Element => {

  const dispatch = useDispatch();
  const franchiseLocations = useSelector(selectFranchiseLocations);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [open, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const franchise = useSelector(selectFranchise);

  useEffect(() => {
    dispatch( setSelectedMainMenu("event") );
    dispatch( setSelectedSubMenu("Keyword Helper" ));
  }, []);

  const locationChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLocation(ev.target.value)
  };

  const handleModalClose = () => {
    setIsOpen(false);
  }

  const scrapeKeywords =  async () => {
    try {
      setLoading(true)
      const location = franchiseLocations.find(item => item.id === selectedLocation);
      if( location == undefined ){
        throw new Error("Could not find the location");
      }
      const url = location.secondUrl

      if ( franchise?.ketsal_url == undefined)
        throw new Error("Missing a service URL");

      const response = await fetch(franchise.ketsal_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: url }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      setKeywords( data.keywords.replace(/^"|"$/g, '').split(', ') )
      setIsOpen(true);
      setLoading(false)

    } catch (error: any) {
      Modal.error({
        title: 'Error',
        content: error.message,
      });

      setLoading(false)
    }
  }

    var preferencesInit = {
        cpaGuard: {
            Email: false, 
            SMS: false,
        },
        keywordGuard: {
            Email: false, 
            SMS: false,
        },
        creativeBoost: {
            Email: false, 
            SMS: false,
        },
        budgetBoost: {
            Email: false, 
            SMS: false,
        },
        audienceCleanup: {
            Email: false, 
            SMS: false,
        },
        keywordClranup: {
            Email: false, 
            SMS: false,
        },
        geoCleanup: {
            Email: false, 
            SMS: false,
        },
        domainCleanup: {
            Email: false, 
            SMS: false,
        },
    }

    const [preferences, setPreferences] = useState(preferencesInit);
    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);
    
    const handleChange = (property:any, index:any) => {
        setPreferences((prevPreferences: any) => ({
          ...prevPreferences,
          [index]: {
            ...prevPreferences[index],
            [property]: !prevPreferences[index][property],
          },
        }));
    };

    const whiteLabel = useSelector( selectWhiteLabel );

  // return (
  //   <div>
  //       <div className="flex flex-wrap">
  //         <div className="basis-6/12 ">
  //           <h1 className="text-2xl mb-5">Keyword helper</h1>
  //         </div>

  //         <div className="basis-6/12 text-right">
  //           <button
  //             className={`bg-white border-[1px] w-28 cursor-pointer border-[--color-primary] font-bold text-[--color-primary] border-solid rounded-[4px] text-center h-14 hover:opacity-[1]`}
  //             onClick={scrapeKeywords}
  //           >
  //             ANALYZE
  //           </button>
  //         </div>


  //         <div className="locations-container flex flex-wrap basis-full mt-4">
  //           <RadioGroup
  //             style={{ width: "100%", flexDirection: "row" }}
  //             onChange={locationChange}
  //             value={selectedLocation}
  //           >

  //             {franchiseLocations.map((location) => (
  //               <LocationCard
  //                 key={`location card: ${location.id}`}
  //                 location={location}
  //                 canEdit={false}
  //                 defaultChecked={franchiseLocations.length == 1}
  //                 disableTooltip={true}
  //               />
  //             ))}
  //           </RadioGroup>
  //         </div>

  //         {
  //           (franchiseLocations.filter(item => item.id === selectedLocation)[0] != null) && (
  //             <KeywordSideBard keyWords={keywords} isOpen={open} closeFunc={handleModalClose} page={franchiseLocations.filter(item => item.id === selectedLocation)[0].secondUrl} />
  //           )
  //         }
          
          
  //         <Backdrop
  //           sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //           open={loading}
  //         >
  //           <LoaderIcon className="loader-icon" />
  //           {/* <CircularProgress color="inherit" /> */}
  //         </Backdrop>
  //       </div>
  //   </div>
  // );

  return (
    <div className="w-[75%] res:w-full">

        <div className="mb-[40px] flex items-center">
            <h1 className="text-2xl">Automated optimizations</h1> 
            <CustomTooltip
                open={showAlwaysOnTooltip}
                onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                title="Enable native optimzations to run automatically"
                placement="right-start"
                arrow
            >
            <InfoCircleOutlined
              style={{ color: "#424242", fontSize: "20px", marginLeft: '15px', marginRight: "20px", marginBottom: "10px" }}
            />
          </CustomTooltip>
        </div>


        <div className="grid grid-cols-2 res:grid-cols-2 gap-4">


            <div className="mb-[20px]">
                <h2 className="font-semibold text-[19px] mb-[19px]">CPA guard</h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={true}
                                onChange={ () => handleChange('Email','cpaGuard')  }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Sets a target CPA when the CPA goes above a certain threshold, then removes it if pacing falls below a certain threshold.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]">
                <h2 className="font-semibold text-[19px] mb-[19px]">Keyword guard</h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={true}
                                onChange={ () => handleChange('Email','keywordGuard')  }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Adds negative keywords for current keywords with high traffic but low conversions.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]">
                <h2 className="font-semibold text-[19px] mb-[19px]">Creative boost</h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={true}
                                onChange={ () => handleChange('Email','creativeBoost')  }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Promotes the share of assets with low impressions but high CTR.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]">
                <h2 className="font-semibold text-[19px] mb-[19px]">Budget boost</h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={true}
                                onChange={ () => handleChange('Email','budgetBoost')  }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Adjusts bid strategy towards clicks if the daily budget falls below the average CPC.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]">
                <h2 className="font-semibold text-[19px] mb-[19px]">Audience cleanup</h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={true}
                                onChange={ () => handleChange('Email','audienceCleanup')  }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Removes converted leads from custom audiences when CRM data is imported.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]">
                <h2 className="font-semibold text-[19px] mb-[19px]">Keyword cleanup</h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={true}
                                onChange={ () => handleChange('Email','keywordCleanup')  }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Periodically removes keywords with a cost-to-CPA ratio below a certain threshold.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]">
                <h2 className="font-semibold text-[19px] mb-[19px]">Geo cleanup</h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={true}
                                onChange={ () => handleChange('Email','geoCleanup')  }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Periodically removes geos with a cost-to-CPA ratio below a certain threshold.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]">
                <h2 className="font-semibold text-[19px] mb-[19px]">Domain cleanup</h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={true}
                                onChange={ () => handleChange('Email','domainCleanup')  }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Periodically removes domains with high impressions but low clicks.</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
  );

};
