import RestService from "./restService";

interface IFlight {
    id: number;
    channel: string;
    //flightDates: string;
    flightStart: string,
    flightEnd: string,
    placementName: string;
    flightBudget: string;
    pacingRequirements: string;
    audience: string;
    remarketing: boolean;
}

export interface ISendOrderFormRequest{
    advertiser: string,
    //channels: string[],
    poNumber: string,
    totalDuration: string,
    totalBudget: string,
    //accountDirector: string, //deprecated
    //projectManager: string, //deprecated
    //mediaLead: string, //deprecated
    //primaryContact: string,
    //billingContact: string,
    clientOrBrand: string,
    flights: IFlight[],
    domain: string;
    notes: string;
}

export interface IUpdateOrderFormRequest{
  id: string,
  advertiser: string,
  //channels: string[],
  poNumber: string,
  totalDuration: string,
  totalBudget: string,
  //accountDirector: string, //deprecated
  //projectManager: string, //deprecated
  //mediaLead: string, //deprecated
  //primaryContact: string,
  //billingContact: string,
  clientOrBrand: string,
  flights: IFlight[],
  domain: string;
  notes: string;
}

//The request sent when attempting to invoice an order.
export interface SendInvoiceOrderFormRequest{
  file: File,
  insertionOrderId: string,
  domain: string,
}

//TODO hacer una llamada backend que obtenga la lista de estos objectos. Por ahora está identica al backend
//Pero debe tener mas campos para poder editar toda la order form.
export interface InsertionOrderFlight{
  id: string,
  channel: string,
  flightStart: string,
  flightEnd: string,
  flightBudget: string,
  placementName: string,
  pacingRequirements: string,
  audience: string,
  remarketing: boolean,
}

export interface InsertionOrder{
  id: string,
  advertiser: string,
  poNumber: string,
  totalDuration: string,
  totalBudget: string,
  //accountDirector: string, //deprecated
  //projectManager: string, //deprecated
  //mediaLead: string, //deprecated
  //primaryContact: string,
  //billingContact: string,
  clientOrBrand: string,
  status: string,
  notes: string,
  insertionOrderFlights: InsertionOrderFlight[] 
}

export interface InsertionOrderBrief{
  id: string,
  date: string,
  poNumber: string,
  status: string,
}

export interface MonthlyInsertionOrders{
  month: string;
  orders: InsertionOrderBrief[];
}

export default class OrderFormService {
  constructor(protected readonly api: RestService) {}

  async sendOrderForm(
    sendOrderForm : ISendOrderFormRequest
  ):Promise<boolean>{
    console.log(sendOrderForm);
    return await this.api.post<boolean>("/order-form", sendOrderForm);
  }

  async sendInvoiceOrderForm(
    sendInvoiceOrderForm : SendInvoiceOrderFormRequest
  ):Promise<boolean>{
    console.log(sendInvoiceOrderForm);
    const formData = new FormData();
    formData.append('file', sendInvoiceOrderForm.file);
    formData.append('insertionOrderId', sendInvoiceOrderForm.insertionOrderId);
    formData.append('domain', sendInvoiceOrderForm.domain);

    return await this.api.post<boolean>(
      "/order-form/invoice",
      formData,
      {
        'Content-Type': 'multipart/form-data'
      }
    );
  }

  async updateOrderForm(
    sendOrderForm : IUpdateOrderFormRequest
  ):Promise<boolean>{
    console.log(sendOrderForm);
    return await this.api.put<boolean>("/order-form", sendOrderForm);
  }

  async getInsertionOrderDetails(insertionOrderId: string):Promise<InsertionOrder>{
    console.log(insertionOrderId)
    return await this.api.get<InsertionOrder>(`/order-form/details?insertionOrderId=${insertionOrderId}`);
  }

  async getMonthlyInsertionOrders():Promise<MonthlyInsertionOrders[]>{
    return await this.api.get<MonthlyInsertionOrders[]>("/order-form/list");
  }

}
