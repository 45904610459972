import RestService from "./restService";
import {Budget, MonthlyBudgets} from "../models/Budget";

export interface BudgetRowSchema {
    franchiseName: string;
    locationName: string; 
    startDate: string;
    budget: number;
}


export default class BudgetServices {
    constructor(protected readonly api: RestService) { }

    async importBudgets(budgetsSchemaArray: BudgetRowSchema[]): Promise<Budget[]> {
        console.log(budgetsSchemaArray);
        const apiResponse = await this.api.post<Budget[]>("/budgets/import", budgetsSchemaArray);
        return apiResponse;
    }

    async getMonthlyBudgetsList(): Promise<MonthlyBudgets[]> {
        return await this.api.get<MonthlyBudgets[]>("/budgets/monthly");
    }
}