import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "../index";
import services from "../../services";
import { InsertionOrder, ISendOrderFormRequest, IUpdateOrderFormRequest, MonthlyInsertionOrders, SendInvoiceOrderFormRequest } from "services/orderFormService";

const initialState = {
    isOpen: false,
    orderFormInvoiced: false,
    orderFormSent: false,
    orderFormErrors: [] as String[],
    loading: true,
    insertionOrder: undefined as InsertionOrder | undefined,
    list: [] as MonthlyInsertionOrders[],
};

export const fetchInsertionOrderDetails = createAsyncThunk(
  "order-form/details",
  async (insertionOrderId: string) => {
    console.log('fetching insertion order details...');
    console.log(insertionOrderId)
    return services.orderFormService.getInsertionOrderDetails(insertionOrderId);
  }
);

export const fetchInsertionOrderList = createAsyncThunk(
  "order-form/fetch",
  async () => {
    console.log('fetching insertion orders...');
    return services.orderFormService.getMonthlyInsertionOrders();
  }
);

export const submitOrderForm = createAsyncThunk<
  boolean,
  ISendOrderFormRequest,
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "order-form/submit",
  async(request, {rejectWithValue}) => {
    try{
      
      let result = await services.orderFormService.sendOrderForm(request);
      return result;

    }catch (error: any) {
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }else{
        //Generic error
        formattedErrors.push("Could not submit the order form. Please try again later.")
      }

      return rejectWithValue(formattedErrors);
    }
  }
)

export const updateOrderForm = createAsyncThunk<
  boolean,
  IUpdateOrderFormRequest,
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "order-form/update",
  async(request, {rejectWithValue}) => {
    try{
      
      let result = await services.orderFormService.updateOrderForm(request);
      return result;

    }catch (error: any) {
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }else{
        //Generic error
        formattedErrors.push("Could not update the order form. Please try again later.")
      }

      return rejectWithValue(formattedErrors);
    }
  }
)

export const sendInvoiceOrderForm = createAsyncThunk<
  boolean,
  SendInvoiceOrderFormRequest,
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "order-form/invoice",
  async(request, {rejectWithValue}) => {
    try{
      
      let result = await services.orderFormService.sendInvoiceOrderForm(request);
      return result;

    }catch (error: any) {
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }else{
        //Generic error
        formattedErrors.push("Could not update the order form. Please try again later.")
      }

      console.log(formattedErrors);

      return rejectWithValue(formattedErrors);
    }
  }
)

export const OrderFormSlice = createSlice({
    name: "orderForm",
    initialState,
    reducers: {
        setOpen: (state, {payload: currentState}: PayloadAction<boolean>) => {
            state.isOpen = currentState;
        },
        setFormSent: (state, {payload: currentState}: PayloadAction<boolean>) => {
            state.orderFormSent = currentState;
        },
        setFormInvoiced: (state, {payload: currentState}: PayloadAction<boolean>) => {
          state.orderFormInvoiced = currentState;
      }
    },
    extraReducers: (builder) =>{
        builder.addCase( submitOrderForm.fulfilled, (state, action) =>{
            state.orderFormSent = true;
            state.orderFormErrors = [];
            state.isOpen = false;
        });

        builder.addCase( submitOrderForm.rejected, (state, action) =>{
            if( action.meta.rejectedWithValue ){
              state.orderFormErrors = action.payload!;
            }
        });

        builder.addCase( updateOrderForm.fulfilled, (state, action) =>{
            state.orderFormSent = true;
            state.orderFormErrors = [];
            state.isOpen = false;
        });

        builder.addCase( updateOrderForm.rejected, (state, action) =>{
            if( action.meta.rejectedWithValue ){
              state.orderFormErrors = action.payload!;
            }
        });

        builder.addCase( sendInvoiceOrderForm.fulfilled, (state, action) =>{
            state.orderFormInvoiced = true;
            state.isOpen = false;
        });

        builder.addCase( sendInvoiceOrderForm.rejected, (state, action) =>{
            if( action.meta.rejectedWithValue ){
              state.orderFormErrors = action.payload!;
            }
        });

        builder.addCase(fetchInsertionOrderList.fulfilled, (state, action) => {
          state.list = action.payload;
          state.loading = false;
        });
        builder.addCase(fetchInsertionOrderList.rejected, (state, action) => {
          state.loading = false;
        });

        builder.addCase(fetchInsertionOrderDetails.fulfilled, (state, action) => {
          state.insertionOrder = action.payload;
          state.loading = false;
        });
        builder.addCase(fetchInsertionOrderDetails.rejected, (state, action) => {
          state.loading = false;
        });
    }
});

export const selectIsOpenState = (state: RootState): boolean => state.orderForm.isOpen;
export const selectIsSentState = (state: RootState): boolean => state.orderForm.orderFormSent;
export const selectIsInvoicedState = (state: RootState): boolean => state.orderForm.orderFormInvoiced;
export const selectOrderErrorsState = (state: RootState): String[] => state.orderForm.orderFormErrors;

export const selectLoading = (state: RootState) : boolean =>
  state.orderForm.loading;

export const selectMonthlyInsertionOrderList = (state: RootState): MonthlyInsertionOrders[] =>
  state.orderForm.list;

export const selectInsertionOrder = (state: RootState): InsertionOrder | undefined =>
  state.orderForm.insertionOrder;

export const {
    setOpen,
    setFormSent,
    setFormInvoiced
} = OrderFormSlice.actions;

export default OrderFormSlice.reducer;