import { Row, Col, Button, Tooltip } from "antd";
import React from "react";
import { connect, useSelector } from 'react-redux';
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import './FileUploader.css';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { ParseResult } from "papaparse";
import { LocationRowSchema } from "../../../services/locationService";
import { importLocations, locationErrors } from "../../../store/Location/LocationStore"
import { RootState } from "../../../store";
import "./styles.css";

interface LocationUploaderProps {
    title: string,
    notifyErrors: Function,
    importLocations: Function,
    locationErrors: String[] | null,
    importLocationsResult: String | null,
}

interface LocationUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
}

class LocationUploader extends React.Component<LocationUploaderProps, LocationUploaderState>{
    constructor(props: any){
        super(props);
        this.state = {
            selectedFile: null,
            fileTypeError: null,
        }
    }

    componentDidUpdate(prevProps: Readonly<LocationUploaderProps>): void {
        
        if( prevProps.locationErrors !== null){
            if( prevProps.locationErrors !== this.props.locationErrors){
                this.props.notifyErrors( 
                    this.props.locationErrors,
                    "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                );
            }
        }
        if( this.props.locationErrors !== null ){
            if( prevProps.importLocationsResult !== this.props.importLocationsResult ){
                this.setState({
                    selectedFile: null,
                });
                alert(this.props.importLocationsResult);
            }
        }
        

    }
    
    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        //Verify if the file is not null.
        if( !event.target.files )
            return;
        
        //Verifies if the file is supported (csv or xlsx)
        let fileType = event.target.files[0].type;
        if( 
            fileType === 'application/vnd.ms-excel' || 
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'text/csv'
        ){
            this.setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
            });
        }else{
            this.setState({
                fileTypeError: "The selected file is not valid. Only CSV and XLSX files are supported."
            });
        }
    }

    onFileRemove = () => {
        this.setState({
            selectedFile: null,
        });
    }

    onFileUpload = () => {
        //This process is different depending on the file type.
        let fileJson = [];

        let selectedFile = this.state.selectedFile;
        
        if( selectedFile?.type === 'text/csv'){
            this.parseLocationsCSVFile(selectedFile).then((json) => {
                console.log('Trying to import the locations csv file...');
                console.log(json);
                this.props.importLocations( json );
            }).catch( (error: Error) => {

            });
        }else{
            this.parseLocationsExcelFile(selectedFile!).then((json) => {
                console.log('Trying to import the locations xlsx file...');
                console.log(json);
                this.props.importLocations( json );
            }).catch( (error: Error) => {
                console.log(error);
            });
        }
    }


    parseLocationsExcelFile = (file: File) => {
        
        return new Promise<LocationRowSchema[]>( (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = XLSX.read(data, { type: "array"} );
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                
                const json = XLSX.utils.sheet_to_json<LocationRowSchema>(
                    worksheet,
                    {
                        //transforms de header names into camelCase.
                        raw: false,
                        header: [
                            'name',
                            'appId',
                            'market',
                            'franchise',
                            'owner',
                            'proximity',
                            'address1',
                            'address2',
                            'city',
                            'state',
                            'postalCode',
                            'country',
                            'latitude',
                            'longitude',
                            'phone',
                            'isRegion',
                            'scope',
                            'scopeLabel',
                            'gadsCampaignId',
                            'fadsCampaignId',
                            'fadsAdSetId',
                            'gaLpKey',
                            'primaryZips',
                            'secondaryZips',
                            'monthlyCpl',
                            'secondUrl',
                            'monthlyBudget',
                            'budgetMode',
                            'importType',
                        ],
                        range: 1,
                        defval: null,
                    }
                );
                
                //The library doesn't seem to support a method to validate
                //empty cells, so we validate this after processing.
                //empty cells are completely ignored by the library, so we need to
                //validate the existence of these missing attributes.
                let errors: string[] = [];
                //The lists of IDs, to keep track if any repeated value is found
                //within the same sheet.
                let appIds: string[] = []; 
                json.forEach( (element, index) => {
                    
                    let rowNum = index+1;

                    if(appIds.includes(element.appId)){
                        errors.push("<b>Row #"+rowNum+"</b> The same AppID value has already been used in this file.")
                    }else{
                        appIds.push(element.appId);
                    }
                    
                    if( element['name']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Name cell.");
                    if( element['appId']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the App ID cell.");
                    if( element['market']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Market cell.");
                    if( element['owner']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Owner cell.");
                    if( element['proximity']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Proximity cell.");
                    if( element['address1']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Address 1 cell.");
                    if( element['address2']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Address 2 cell.");
                    if( element['city']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the City cell.");
                    if( element['state']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the State cell.");
                    if( element['postalCode']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Postal Code cell.");
                    if( element['country']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Country cell.");
                    // if( element.latitude === "")
                    //     errors.push("<b>Row #"+rowNum+"</b> is missing the Latitude cell.");
                    // if( element.longitude === "")
                    //     errors.push("<b>Row #"+rowNum+"</b> is missing the Longitude cell.");
                    if( element.phone === "")
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Phone cell.");
                    if( element.gadsCampaignId === "")
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Gads Campaign ID cell.");
                    if( element.fadsAdSetId === "")
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Fads Ad Set ID cell.");
                    if( element.fadsCampaignId === "")
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Fads Campaign ID cell.");
                    if( element.gaLpKey === "")
                        errors.push("<b>Row #"+rowNum+"</b> is missing the GA lp Key Ad Set ID cell.");
                    if( element.isRegion === false)
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Region cell.");
                    // if( element.scope === "")
                    //     errors.push("<b>Row #"+rowNum+"</b> is missing the Scope cell.");
                    // if( element.scopeLabel === "")
                    //     errors.push("<b>Row #"+rowNum+"</b> is missing the Scope Label cell.");
                    if( element.monthlyCpl.length <= 0 )  
                        errors.push("<b>Row #"+rowNum+"</b> is missing the monthly cpl cell.");
                    if( element.monthlyBudget.length <= 0 )  
                        errors.push("<b>Row #"+rowNum+"</b> is missing the monthly budget cell.");
                    if( element.budgetMode === "")
                        errors.push("<b>Row #"+rowNum+"</b> is missing the budget mode cell.");
                    if( element.importType === "")
                        errors.push("<b>Row #"+rowNum+"</b> is missing the import type cell.");
                });

                //Reject this promise and notify the parent about the errors
                //to display in the large alert window.
                if( errors.length > 0 ){
                    
                    this.props.notifyErrors( 
                        errors, 
                        "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                    );

                    reject(Error('There were some errors in the file.'));
                }

                console.log(json)

                resolve(json);
            }
            reader.readAsArrayBuffer(file);
        });

    }

    parseLocationsCSVFile = ( file: File ) => {
        return new Promise<LocationRowSchema[]>( (resolve, reject) => {
            Papa.parse( file, {
                header: true,
                transformHeader: ((header: string) => this.camelize(header)),
                complete: (results: ParseResult<LocationRowSchema> ) => {
                    
                    //Need to adjust the header names, and we do so while
                    //manually validating each row.
                    let json = results.data;
                    let errors: string[] = [];
                    //The lists of IDs, to keep track if any repeated value is found
                    //within the same sheet.
                    let appIds: string[] = []; 
                    json.forEach( (element, index) => {
                        console.log(element)

                        let rowNum = index+1;
                        //Check if each row has fields with actual values.
                        //the csv library fills the nonexistent fields with empty strings
                        if(appIds.includes(element.appId)){
                            errors.push("<b>Row #"+rowNum+"</b> The same AppID value has already been used in this file.")
                        }else{
                            appIds.push(element.appId);
                        }

                        if( element.name === "")
                            errors.push("<b>Row #"+rowNum+" - "+ element.name +"</b> is missing the Name cell.");
                        if( element.appId === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the App ID cell.");
                        if( element.market === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Market cell.");
                        if( element.franchise === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Franchise cell.");
                        if( element.owner === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Owner cell.");
                        if( element.proximity === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Proximity cell.");
                        if( element.address1 === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Address 1 cell.");
                        if( element.address2 === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Address 2 cell.");
                        if( element.city === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the City cell.");
                        if( element.state === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the State cell.");
                        if( element.postalCode === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Postal Code cell.");
                        if( element.country === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Country cell.");
                        if( element.latitude === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Latitude cell.");
                        if( element.longitude === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Longitude cell.");
                        // if( element.latitude === "")
                        //     errors.push("<b>Row #"+rowNum+"</b> is missing the Latitude cell.");
                        // if( element.longitude === "")
                        //     errors.push("<b>Row #"+rowNum+"</b> is missing the Longitude cell.");
                        if( element.phone === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Phone cell.");
                        if( element.gadsCampaignId === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Gads Campaign ID cell.");
                        if( element.fadsAdSetId === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Fads Ad Set ID cell.");
                        if( element.fadsCampaignId === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Fads Campaign ID cell.");
                        if( element.gaLpKey === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the GA lp Key Ad Set ID cell.");
                        if( element.isRegion === false)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Region cell.");
                        // if( element.scope === "")
                        //     errors.push("<b>Row #"+rowNum+"</b> is missing the Scope cell.");
                        // if( element.scopeLabel === "")
                        //     errors.push("<b>Row #"+rowNum+"</b> is missing the Scope Label cell.");
                        if( element.monthlyCpl.length <= 0 )  
                            errors.push("<b>Row #"+rowNum+"</b> is missing the monthly cpl cell.");
                        if( element.monthlyBudget.length <= 0 )  
                            errors.push("<b>Row #"+rowNum+"</b> is missing the monthly budget cell.");
                        if( element.budgetMode === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing budget mode cell.");
                        if( element.importType === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing import type cell.");
           
                    });

                    //Reject this promise and notify the parent about the errors
                    //to display in the large alert window.
                    if( errors.length > 0 ){
                        
                        this.props.notifyErrors( 
                            errors, 
                            "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                        );

                        reject(Error('There were some errors in the file.'));
                    }
                    
                    resolve(json);
                    
                },
                error: error => {
                    reject( Error( error.message ) );
                }
            });
        });
    }

    //TODO: Esto no debe estar aquí.
    camelize = (str: string) => {
        
        str = str.toLowerCase();
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
        {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    render(){
        
        let button;
        if( this.state.selectedFile ){
            button = 
                <div>
                    <div onClick={this.onFileUpload}
                        className="primary-button pointer-hover p-[8px_25px]">
                        <div className="primary-button-text">UPLOAD</div>
                    </div>
                </div>
        }else{
            button = 
                <div>
                    <input id={"file-uploader-location"} type="file" onChange={this.onFileChange} />
                    <label htmlFor={"file-uploader-location"}>
                        <div className="primary-button pointer-hover p-[8px_25px]">
                            <div className="primary-button-text">IMPORT</div>
                        </div>
                    </label>
                </div>
        }
        
        return (
            <div className="file-upload-area mb-10">
                <h1>{this.props.title}</h1>
                <Row>
                    <Col >
                        { button }
                    </Col>
                    <Col offset={1} span={6}>
                        { this.state.selectedFile !== null &&
                            <div>
                                <p className="file-upload-file-name">{this.state.selectedFile.name}</p>
                                <span onClick={this.onFileRemove} className="file-upload-remove-button">REMOVE</span>
                            </div>
                        }

                        { this.state.selectedFile == null && 
                            <a target="_blank" download={true} className="template-download" href="/templates/template_location.xlsx">Template</a>
                        }

                        {
                            this.state.fileTypeError !== null &&
                            <div>
                                <p className="error">{this.state.fileTypeError}</p>
                            </div>
                        }

                        

                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        importLocations: ( locationArray: LocationRowSchema[] ) => dispatch(importLocations({locationSchemaArray: locationArray}) )
    }
}

const mapStateToProps = (state: RootState) => {
    return{
        locationErrors: state.locations.locationErrors,
        importLocationsResult: state.locations.importLocationsResult
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationUploader);
