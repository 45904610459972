import { Button, Checkbox, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appColors } from "../../../assets/variables/colors";
import { AvailableRoutes } from "../../../pages/Routes";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down-orange.svg";
import services from "../../../services";
import {
  removeTargetZone,
  savePreviewArea,
  savePreviewAudience,
  saveTargetZone,
  selectDetailsLocation,
  selectDisplayCrosswalkOverlay,
  selectDisplayRedemptionsOverlay,
  selectHandleCreateAudience,
  selectIsCustomTargetArea,
  selectIsNegativeCustomTargetArea,
  selectIsNegativeZone,
  selectIsZoneSelected,
  selectNegativeTargetZonePoints,
  selectNegativeZoneRadius,
  selectPreviewArea,
  selectPreviewAudience,
  selectSavedTargetZones,
  selectSelectedLocation,
  selectSingleLocation,
  selectTargetZoneChanged,
  selectTargetZonePoints,
  selectZoneRadius,
  setDetailsLocation,
  setIsNegativeZone,
  setIsZoneSelected,
  setNegativeTargetZonePoints,
  setNegativeZoneRadius,
  setTargetZoneChanged,
  setZoneRadius,
  toggleDisplayCrosswalk,
  toggleDisplayRedemptions,
  toggleIsCustomTargetArea,
  toggleIsNegativeCustomTargetArea,
  selectAudiences,
  fetchAudiences,
  setAudienceSegment,
  selectAudienceSegment
} from "../../../store/campaignForm/CampaignForm";
import {
  CalculateCircleArea,
  CalculatePolygonArea,
  metersInAMile,
} from "../../../utility/geography";
import CustomSwitch from "../../Switch/CustomSwitch";
import "./TargetZone.css";
import { ReactComponent as IconMore } from "../../../assets/icons/more-vertical.svg";
import { TargetZoneSidbar } from "./TargetZoneSidbar";
import { setHandleCreateAudience } from "../../../store/campaignForm/CampaignForm";
import { getSubdomain } from "../../../utility/subdomain";
import { segments } from "./SegmentsList";
interface TargetZoneFormProps {
  setLoading: (value: boolean) => void;
}

const showDemo = process.env.REACT_APP_APP_SHOW_DEMO_MATERIAL === "true";
const showCrossWalk = process.env.REACT_APP_APP_SHOW_CROSSWALK === "true";


const TargetZoneForm = ({ setLoading }: TargetZoneFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const points = useSelector(selectTargetZonePoints);
  const negativePoints = useSelector(selectNegativeTargetZonePoints);
  const isCustomTargetArea = useSelector(selectIsCustomTargetArea);
  const isNegativeCustomTargetArea = useSelector(
    selectIsNegativeCustomTargetArea
  );
  const zoneRadius = useSelector(selectZoneRadius); // in meters!
  const negativeZoneRadius = useSelector(selectNegativeZoneRadius); // in meters!
  const audienceSegment = useSelector( selectAudienceSegment );
  //const [audienceSegment, setAudienceSegment] = useState<string[]>([]);
  const [showSavedZones, setShowSavedZones] = useState<boolean>(false);
  const crosswalkOverlay: boolean = useSelector(selectDisplayCrosswalkOverlay);
  const redemptionsOverlay: boolean = useSelector(
    selectDisplayRedemptionsOverlay
  );
  const isZonesSaved = useSelector(selectIsZoneSelected);
  const { Option } = Select;
  const [negativeZoneDisabled, setNegativeZoneDisabled] =
    useState<boolean>(true);
  const isNegativeZone = useSelector(selectIsNegativeZone);
  const previewArea = useSelector(selectPreviewArea);
  const previewAudience = useSelector(selectPreviewAudience);
  const savedZones = useSelector(selectSavedTargetZones);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const detailsLocation = useSelector(selectDetailsLocation);
  const singleLocation = useSelector(selectSingleLocation);
  const handleCreateAudience = useSelector(selectHandleCreateAudience);
  const [callSavePreviewZone, setCallSavePreviewZone] = useState(false);
  const targetZoneChanged = useSelector(selectTargetZoneChanged);

  const whiteLabel = useSelector(selectWhiteLabel);
  const selectedAudiences = useSelector(selectAudiences) || [];

  // const allSegments = selectedAudiences.map((audienceItem) => {
  //     console.log('Audience', audienceItem);
  //     return <Option key={audienceItem.hash} value="M">{audienceItem.name}</Option>
  // });

  useEffect(() => {
      if (selectedAudiences.length < 1) {
          dispatch(fetchAudiences());
      }
  }, [selectedAudiences]);

  useEffect(() => {
    setNegativeZoneDisabled(
      isCustomTargetArea
        ? !(points.length > 2)
        : !(points.length === 1 && zoneRadius > 0)
    );
    dispatch(setIsNegativeZone(false));
    dispatch(setNegativeTargetZonePoints([]));
    dispatch(setNegativeZoneRadius(0));
  }, [points, isCustomTargetArea, zoneRadius]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (audienceSegment) {
      dispatch(setHandleCreateAudience(makePreview));
    }
  }, [audienceSegment, isCustomTargetArea, zoneRadius, negativePoints, negativeZoneRadius, points]);

  const savePreviewZone = async (): Promise<void> => {
    if (points.length > 0) {
      dispatch(
        saveTargetZone({
          points: isCustomTargetArea ? points : undefined,
          area: previewArea,
          audience: previewAudience,
          center: isCustomTargetArea ? undefined : points[0],
          radiusInMiles: isCustomTargetArea
            ? undefined
            : zoneRadius / metersInAMile,
          negativePoints:
            negativePoints.length > 2 ? negativePoints : undefined,
          negativeCenter:
            negativePoints.length === 1 ? negativePoints[0] : undefined,
          negativeRadiusInMiles: negativeZoneRadius
            ? negativeZoneRadius / metersInAMile
            : undefined,
          // TODO: Add male female selected
          //selectors: audienceSegment,
        })
      );
    }
    dispatch(savePreviewArea(0));
    dispatch(savePreviewAudience(0));
    dispatch(setTargetZoneChanged(false));
  };
  useEffect(() => {
    if (callSavePreviewZone) {
      savePreviewZone();
      setCallSavePreviewZone(false);
    }
  }, [callSavePreviewZone]);

  const makePreview = async (): Promise<void> => {
    let gender = "";
    let age21_39 = false;
    let age40_49 = false;
    let age50_80 = false;
    let age80 = false;
    let income60 = false;
    let income60_100 = false;
    let income100 = false;
    if (audienceSegment.includes("M") && audienceSegment.includes("F")) {
      // If both are included, don't add the param
      gender = "";
    } else if (audienceSegment.includes("M")) {
      gender = "M";
    } else if (audienceSegment.includes("F")) {
      gender = "F";
    }
    if (audienceSegment.length !== 0) {
      audienceSegment.map((segment) => {
        if (segment === "age21_39") age21_39 = true;
        if (segment === "age40_49") age40_49 = true;
        if (segment === "age50_80") age50_80 = true;
        if (segment === "age80") age80 = true;
        if (segment === "income60") income60 = true;
        if (segment === "income60_100") income60_100 = true;
        if (segment === "income100") income100 = true;
        return segment;
      });
    }

    if (isCustomTargetArea) {
      if (points.length > 2) {
        const areaInMeters = CalculatePolygonArea([...points, points[0]]);
        const areaInMiles = areaInMeters / (metersInAMile * metersInAMile);

        dispatch(savePreviewArea(areaInMiles));

        setLoading(true);
        try {
          const audienceResponse =
            await services.audienceService.getAudienceInPolygon(points, {
              gender,
              negativeCoordinates: negativePoints,
              age21_39,
              age40_49,
              age50_80,
              age80,
              income60,
              income60_100,
              income100,
              negativeCenter: negativePoints[0],
              negativeRadiusInMiles: negativeZoneRadius / metersInAMile,
            });
          dispatch(savePreviewAudience(audienceResponse.total));
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
        setCallSavePreviewZone(true);
      }
    } else {
      const areaInMeters = CalculateCircleArea(zoneRadius);
      const areaInMiles = areaInMeters / (metersInAMile * metersInAMile);

      dispatch(savePreviewArea(areaInMiles));

      setLoading(true);
      try {
        const audienceResponse =
          await services.audienceService.getAudienceInCircle(
            points[0],
            zoneRadius / metersInAMile,
            {
              negativeCenter: negativePoints[0],
              negativeRadiusInMiles: negativeZoneRadius / metersInAMile,
              gender,
              age21_39,
              age40_49,
              age50_80,
              age80,
              income60,
              income60_100,
              income100,
              negativeCoordinates: negativePoints,
            }
          );
        dispatch(savePreviewAudience(audienceResponse.total));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
      setCallSavePreviewZone(true);
    }
  };

  const audienceSegmentChange = (value: any) => {
    dispatch(setAudienceSegment(value));
    dispatch(setTargetZoneChanged(true));
  };

  return (
    <div className={classNames.form + " res:!mb-0"}>
      {false && showDemo && (
        <div className="target-zone-mobile-customer-view-margins">
          <h2>Apply Customer View</h2>
          <div>
            <Button
              onClick={() => {
                dispatch(toggleDisplayRedemptions());
              }}
              style={{
                marginRight: "20px",
                borderRadius: "40px",
                width: "100px",
                padding: "0px",
                fontSize: "12px",
                borderColor: whiteLabel.secondaryColor,
                backgroundColor: redemptionsOverlay
                  ? whiteLabel.primaryColor
                  : "",
                borderWidth: redemptionsOverlay ? "0px" : "1px",
                color: redemptionsOverlay
                  ? "#ffffff"
                  : whiteLabel.secondaryColor,
              }}
            >
              REDEMPTIONS
            </Button>
            {showCrossWalk &&
              <Button
                onClick={() => {
                  dispatch(toggleDisplayCrosswalk());
                }}
                style={{
                  borderRadius: "40px",
                  width: "100px",
                  padding: "0px",
                  fontSize: "12px",
                  borderColor: appColors.SECONDARY_PURPLE,
                  backgroundColor: crosswalkOverlay
                    ? appColors.PRIMARY_ORANGE
                    : "",
                  borderWidth: crosswalkOverlay ? "0px" : "1px",
                  color: crosswalkOverlay
                    ? "#ffffff"
                    : appColors.SECONDARY_PURPLE,
                }}
              >
                CROSSWALK
              </Button>}
          </div>
        </div>
      )}

      <div className="location-info" style={{ marginBottom: '16px' }}>
        <span className="name"><b>Buying for:</b> {selectedLocation?.name}</span>
        <Button className="change-link" type="link" onClick={() => {
          if (singleLocation) {
            if (detailsLocation) dispatch(setDetailsLocation(null));
            else dispatch(setDetailsLocation(selectedLocation));
          } else {
            navigate(AvailableRoutes.LOCATIONS);
          }
        }}>{singleLocation ? 'Details' : 'Change'}</Button>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h2>Target area</h2>
        <div className={classNames.switchRow}>
          <p>Custom</p>
          <CustomSwitch
            checked={!isCustomTargetArea}
            onChange={() => {
              dispatch(toggleIsCustomTargetArea());
              dispatch(setTargetZoneChanged(true));
            }}
            switchColor={whiteLabel.primaryColor}
          />
          <p style={{ marginLeft: "14px" }}>Radius</p>
          {!isCustomTargetArea && (
            <Input
              onChange={(e) => {
                dispatch(setZoneRadius(Number(e.target.value) * metersInAMile)); // convert to meters!
                dispatch(setTargetZoneChanged(true));
              }}
              style={{ width: "85px", marginLeft: "10px" }}
              type="number"
              placeholder="Radius"
              min={0}
              defaultValue={20}
            />
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: "14px", marginBottom: "14px" }}>
          <Checkbox
            disabled={negativeZoneDisabled}
            checked={isNegativeZone}

            onChange={(e: any) => {
              dispatch(setIsNegativeZone(e.target.checked));
              dispatch(setTargetZoneChanged(true));
            }}>
            Negative targeting
          </Checkbox>
        </div>
        {isNegativeZone && (
          <div className={classNames.switchRow}>
            <p>Custom</p>
            <CustomSwitch
              checked={!isNegativeCustomTargetArea}
              onChange={() => {
                dispatch(toggleIsNegativeCustomTargetArea());
                dispatch(setTargetZoneChanged(true));
              }}
              switchColor={whiteLabel.primaryColor}
            />
            <p style={{ marginLeft: "14px" }}>Radius</p>
            {!isNegativeCustomTargetArea && (
              <Input
                onChange={(e) => {
                  dispatch(
                    setNegativeZoneRadius(
                      Number(e.target.value) * metersInAMile
                    )
                  )
                  dispatch(setTargetZoneChanged(true));
                }
                } // convert to meters!
                style={{ width: "85px", marginLeft: "10px" }}
                type="number"
                placeholder="Radius"
                min={0}
              />
            )}
          </div>
        )}
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h2>Audience segments</h2>
        <div>
          <Select
            mode="multiple"
            style={{ fontSize: "16px", width: "100%" }}
            id="segment"
            defaultValue={audienceSegment}
            onChange={audienceSegmentChange}
            placeholder="Select custom segments"
          >
              {segments}
          </Select>
        </div>
      </div>

      <div
        className="target-zone-mobile-buttons res:bg-white res:!p-6 res:!h-auto res:!flex !hidden"
        style={{ display: "flex", alignSelf: "flex-end" }}
      >

{ savedZones.length > 0 && (
          <div className="res:!h-auto">
            { showSavedZones && savedZones.map((zone, index) => (
                <div
                  key={`target zone info sidebar card: ${index}`}
                  className="target-zone-sidebar-card res:mb-3 res:pb-4 res:mt-0"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3 style={{ marginBottom: '1em', fontSize: '14px', fontWeight: 'bold' }}>Audience Preview</h3>
                    <TrashIcon style={{ cursor: "pointer" }} onClick={() => dispatch(removeTargetZone(zone))} />
                  </div>
                  <p style={{ fontSize: '12px' }}>
                    <span style={{ fontWeight: "bold", height: '20px', marginBottom: '1em' }}>Estimated Count:</span>{" "}
                    {zone.audience.toLocaleString()}
                  </p>
                  <p style={{ fontSize: '12px' }}>
                    <span style={{ fontWeight: "bold", height: '20px', marginBottom: '1em' }}>Estimated Area:</span>{" "}
                    {zone.area.toFixed(2).toLocaleString()} mi<sup>2</sup>
                  </p>
                </div>
              )
            )}
            <p 
              className=" text-black text-center text-lg res:flex items-center justify-center mb-0"
              onClick={()=> { setShowSavedZones(!showSavedZones)} }
            >
              Audiences generated: {savedZones.length}
              { showSavedZones && (<ArrowIcon className="res:ml-3 mobile-arrow svg-color-primary " style={{ cursor: "pointer"}} />) }
              { !showSavedZones && (<ArrowIcon className="res:ml-3 mobile-arrow svg-color-primary " style={{ cursor: "pointer", transform: "rotate(180deg)"}} />) }
            </p>
            
            
          </div>
        )}

        <Button
          className="sidebar-inverted-button pointer-hover target-zone-mobile-button mobile-target-zone-button-height"
          onClick={handleCreateAudience}
          disabled={!targetZoneChanged}
        >
          <span className="sidebar-inverted-button-text">
            CREATE AUDIENCE
          </span>
        </Button>

        <Button
          className="preview-save-zone-button target-zone-mobile-button mobile-target-zone-button-height"
          disabled={savedZones.length < 1}
          onClick={() => {
            if (savedZones.length > 0) navigate(AvailableRoutes.CHANNELS);
            else dispatch(setIsZoneSelected(false));
          }}
        >
          CHOOSE CAMPAIGN TYPE
        </Button>

        
        
        
        

        <IconMore
          className="mobile-more-icon target-zone-mobile-button res:hidden"
          onClick={() => setShowMobileSidebar(!showMobileSidebar)}
        />
      </div>
      {showMobileSidebar && (
        <div className="mobile-sidebar-container">
          <div
            className="mobile-sidebar-oppacity"
            onClick={() => setShowMobileSidebar(false)}
          ></div>
          <div className="mobile-sidebar">
            <TargetZoneSidbar />
          </div>
        </div>
      )}
    </div>
  );
};

const classNames = {
  form: "target-zone-form",
  switchRow: "target-zone-switch-row",
};

export default React.memo(TargetZoneForm);
