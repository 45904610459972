import { useEffect, useState } from "react";
import { Form, Input, Button, Divider } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import "./styles.css";
import { ReactComponent as IconSignIn } from "../../../assets/icons/sign-in.svg";
import { useAuth } from "../../../hooks/useAuth";
import FooterHelp from "../FooterHelp";

const errorMessageMapping: ErrorMessage = {
  invalidCredential:
    "Sorry, we couldn’t find an account with that email and password combination",
  notConfirmed: "User is not confirmed.",
};

const LogIn = () => {
  const {
    login,
    isRegisteredSuccess,
    resetPasswordSuccess,
    setRegisteredSuccess,
    confirmRegistration,
    isConfirmedSignUp,
    recoveryPassword,
    loading,
    error,
  } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const [isRecoveryPassword, setIsRecoveryPassword] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.type === "confirmsignup" && !loading && !isConfirmedSignUp) {
      confirmRegistration(params);
    }

    if (params.type === "recoverypassword") {
      setIsRecoveryPassword(true);
    } else {
      setIsRecoveryPassword(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, loading, isConfirmedSignUp]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      navigate("/login");
    }
  }, [resetPasswordSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isConfirmedSignUp) {
      navigate("/login");
    }
  }, [isConfirmedSignUp]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (values: LogInParams) => {
    if (params.type === "recoverypassword") {
      recoveryPassword({ ...values, code: params.code });
    } else {
      login(values);
    }
    setSubmitted(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!error && submitted && !loading) {
      console.log("Login Complete");
      //window.location.reload();
    } else if (error && !loading) {
      setSubmitted(false);
    }
  }, [error, loading, submitted]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  const handleOnSignUp = () => {
    setRegisteredSuccess();
    navigate("/signup");
  };

  const handleOnResetPassword = () => {
    setRegisteredSuccess();
    navigate("/reset-password");
  };

  return (
    <div className="container-auth res:w-full">
      <div>
        {isRecoveryPassword ? (
          <div className="text-header">Password Recovery</div>
        ) : (
          <div className="text-header">Welcome Back</div>
        )}
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="container-form"
          layout="vertical"
          initialValues={{
            email: params.email,
          }}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "not valid email",
                required: true,
              },
            ]}
          >
            <Input
              placeholder="Enter email"
              className={error && "error-input"}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            style={{ marginBottom: 2 }}
            rules={[{ required: true }]}
          >
            {isRecoveryPassword ? (
              <Input.Password
                placeholder="Enter your new password"
                className={error && "error-input"}
              />
            ) : (
              <Input.Password
                placeholder="Enter password"
                className={error && "error-input"}
              />
            )}
          </Form.Item>

          <Form.Item>
            <div className="section-leyend">
              <div className="password-forgot" onClick={handleOnResetPassword}>
                Forgot Your Password?
              </div>
            </div>
          </Form.Item>

          <Form.Item style={{ marginTop: 60 }}>
            <Button type="primary" htmlType="submit" className="button-submit">
              {isRecoveryPassword ? "Update Password" : "SIGN IN"}
              <IconSignIn style={{ height: 11 }} />
            </Button>
          </Form.Item>

          {!isRecoveryPassword ? (
            <Divider plain>or</Divider>
          ) : (null)}
          {!isRecoveryPassword ? (
            <Form.Item>
              <Button
                type="primary"
                className="button-register"
                onClick={handleOnSignUp}
              >
                REGISTER
              </Button>
            </Form.Item>
          ) : (null)}

        </Form>
        {error && (
          <div className="error-message">
            {error.message === "User is not confirmed."
              ? errorMessageMapping.notConfirmed
              : errorMessageMapping.invalidCredential}
          </div>
        )}

        {isRegisteredSuccess && (
          <div className="success-message">
            Thank you for registering, please check your email
          </div>
        )}

        {isConfirmedSignUp && (
          <div className="success-message">
            Your account has been confirmed, please re-enter your credentials.
          </div>
        )}

        {isRecoveryPassword && (
          <div className="success-message">
            Your password has been reset, enter your new password.
          </div>
        )}
      </div>
      <FooterHelp />
    </div>
  );
};

interface LogInParams {
  email: string;
  password: string;
}

interface ErrorMessage {
  invalidCredential: string;
  notConfirmed: string;
}

export default LogIn;
