import axios from "axios";
import AdCampaignService from "./adCampaignService";
import AuthService from "./AuthService";
import ChannelService from "./channelService";
import CreativeTemplateService from "./creativeTemplateService";
import CustomPackageService from "./customPackageService";
import EventService from "./eventService";
import LocationService from "./locationService";
import RestService from "./restService";
import AudienceService from "./stiristaDataService";
import UserService from "./userService";
import QRLandingCodesService from "./QRLandingCodesService";
import QRPromCodesService from "./QRPromCodesService";
import FranchiseService from "./franchiseService";
import AdminUserService from "./adminUserService";
import WhiteLabelService from "./whiteLabelService";
import CustomReportService from "./customReportService";
import AudienceSourceService from "./audienceSourceService";
import QRCampaignService from "./QRCampaignService";
import MediaTemplateService from "./mediaTemplateService";
import MonthlyInvoicesListService from "./monthlyInvoicesListService";
import FileItemService from "./FileItemService";
import FileService from "./fileService";
import OrderFormService from "./orderFormService";
import GoogleAdsCampaignService from "./googleAdsCampaignService";
import AlertService from "./alertService";
import DataLayerService from "./DataLayerService";
import CustomKeywordService from "./customKeywordService";
import CustomCreativeService from "./customCreativeService";
import EmailService from "./EmailService"; 
import BudgetService from "./BudgetService";
import SpendPacingReportService from "./spendPacingReportService";

const authService = new AuthService();
const apiService = new RestService(
  axios,
  authService,
  process.env.REACT_APP_PLATFORM_API ?? "http://localhost:5000"
);
// Probably getting rid of this, probably get all data through our api for security
const bigQueryService = new RestService(
  axios,
  authService,
  process.env.REACT_APP_DEMOGRAPHIC_API ??
    "https://channel-api-dev-dot-jbecca-platform.uc.r.appspot.com"
);

const sourceService = new RestService(
    axios,
    authService,
    process.env?.REACT_APP_AUDIENCE_SOURCE_API || 'http://localhost:8888'
);

const fileService = new FileService(
    axios,
    authService,
    process.env.REACT_APP_PLATFORM_API ?? "http://localhost:5000"
);

const adCampaignService = new AdCampaignService(apiService);
const userService = new UserService(apiService);
const adminUserService = new AdminUserService(apiService);
const eventService = new EventService(apiService);
const channelService = new ChannelService(apiService);
const customPackageService = new CustomPackageService(apiService);
const creativeTemplateService = new CreativeTemplateService(apiService);
const locationService = new LocationService(apiService);
const audienceService = new AudienceService(bigQueryService);
const qrLandingCodesService = new QRLandingCodesService(apiService);
const qrPromCodesService = new QRPromCodesService(apiService);
const qrCampaignService = new QRCampaignService(apiService);
const franchiseService = new FranchiseService(apiService);
const whiteLabelService = new WhiteLabelService(apiService);
const customReportService = new CustomReportService(apiService);
const audienceSourceService = new AudienceSourceService(sourceService);
const mediaTemplateService = new MediaTemplateService(apiService);
const monthlyInvoicesListService = new MonthlyInvoicesListService(apiService);
const fileItemService = new FileItemService(fileService);
const orderFormService = new OrderFormService(apiService);
const googleAdsCampaignService = new GoogleAdsCampaignService(apiService);
const alertService = new AlertService(apiService);
const dataLayerService = new DataLayerService();
const customKeywordService = new CustomKeywordService(apiService);
const customCreativeService = new CustomCreativeService(apiService);
const emailService = new EmailService(apiService);
const budgetService = new BudgetService(apiService);
const spendPacingReportService = new SpendPacingReportService(apiService);

const services = {
  apiService,
  authService,
  userService,
  adminUserService,
  adCampaignService,
  eventService,
  channelService,
  customPackageService,
  creativeTemplateService,
  locationService,
  franchiseService,
  audienceService,
  qrLandingCodesService,
  qrPromCodesService,
  qrCampaignService,
  whiteLabelService,
  customReportService,
  audienceSourceService,
  mediaTemplateService,
  monthlyInvoicesListService,
  fileItemService,
  orderFormService,
  googleAdsCampaignService,
  alertService,
  dataLayerService,
  customKeywordService,
  customCreativeService,
  emailService,
  budgetService,
  spendPacingReportService
};

export default services;
