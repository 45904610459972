import React, {useEffect, useState} from "react";
import { Form, Input, Button, Space, Radio, Select, Checkbox } from "antd";
import {Row, Col, Drawer} from "antd";
import { useDispatch, useSelector } from "react-redux"
import styles from "./PlatformSupport.module.css";
import { ReactComponent as IconArrowRightBlack } from "../../../assets/icons/arrow-right-black.svg";
import { setOpen, selectIsOpenState, submitOrderForm, selectIsSentState, setFormSent, selectOrderErrorsState, fetchInsertionOrderDetails, selectInsertionOrder, updateOrderForm, sendInvoiceOrderForm, selectIsInvoicedState, setFormInvoiced } from "../../../store/PlatformSupport/OrderForm";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { getCustomChannels, selectCustomChannels } from "../../../store/CustomReport/CustomReportStore";
import { ISendOrderFormRequest, IUpdateOrderFormRequest, SendInvoiceOrderFormRequest } from "services/orderFormService";
import DatePicker from "components/datepicker/DatePicker";

interface Flight {
  id: number;
  channel: string;
  //flightDates: string;
  flightStart: string;
  flightEnd: string;
  placementName: string;
  flightBudget: string;
  pacingRequirements: string;
  audience: string;
  remarketing: boolean;
}

const flightDictionary: Record<string, string> = {
  //flightDates: 'Flight dates',
  flightStart: 'Start date',
  flightEnd: 'End date',
  channel: 'Channel',
  placementName: 'Placement name',
  flightBudget: 'Flight budget',
  pacingRequirements: 'Pacing requirements',
  audience: 'Audience',
  remarketing: 'Remarketing',
};

export const OrderForm  = (
  {
    editMode = false,
    sendMode = false,
    insertionOrderId = "",
  }
) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsOpenState);
    const isSent = useSelector(selectIsSentState);
    
    const insertionOrder = useSelector(selectInsertionOrder);
    const orderErrors = useSelector(selectOrderErrorsState);
    const [open, setModalOpen] = useState(false);
    const whiteLabel = useSelector( selectWhiteLabel );
    const customChannels = useSelector(selectCustomChannels);
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
    const [fileUploadMode, setFileUploadMode] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    //By default add one flight.
    const [flights, setFlights] = useState<Flight[]>([{
      id: 1,
      channel: '',
      placementName: '',
      flightStart: '',
      flightEnd: '',
      flightBudget: '',
      pacingRequirements: '',
      audience: '',
      remarketing: false,
    }]);

    const [form] = Form.useForm();

    /**
     * Handles submit
     */

    const onFinish = (e: any) => {
      if( editMode ){
        const updateFormRequest = {
          id: insertionOrderId,
          advertiser: e.advertiser,
          //channels: e.channels,
          poNumber: e.poNumber,
          totalDuration: e.totalDuration,
          totalBudget: e.totalBudget,
          //primaryContact: e.primaryContact,
          //billingContact: e.billingContact,
          clientOrBrand: e.clientOrBrand,
          flights: flights,
          notes: e.notes,
          domain: whiteLabel.domain
        } as IUpdateOrderFormRequest;

        dispatch(updateOrderForm(updateFormRequest));
      }else{
        const sendFormRequest = {
          advertiser: e.advertiser,
          //channels: e.channels,
          poNumber: e.poNumber,
          totalDuration: e.totalDuration,
          totalBudget: e.totalBudget,
          //primaryContact: e.primaryContact,
          //billingContact: e.billingContact,
          clientOrBrand: e.clientOrBrand,
          flights: flights,
          notes: e.notes,
          domain: whiteLabel.domain
        } as ISendOrderFormRequest;

        dispatch(submitOrderForm(sendFormRequest));
      }
    }

    /** 
     * Handles invoice "submit"
    */
    const onSendInvoice = () => {
      const sendInvoiceRequest = {
        file: selectedFile,
        insertionOrderId: insertionOrderId,
        domain: whiteLabel.domain
      } as SendInvoiceOrderFormRequest;

      dispatch(sendInvoiceOrderForm(sendInvoiceRequest));

    }

    /** 
     * Checks if all fields are validated
    */
    const handleFormChange = () => {
      
      const fields = form.getFieldsValue();

      //Excludes the check of "remarketing" fields.
      const fieldsToCheck = Object.keys(fields).filter(key => !key.includes('remarketing') && key !== 'notes');
      const emptyFields = fieldsToCheck.filter(key => !fields[key]);


      if (emptyFields.length > 0) {
        setIsDisabledSubmit(true)
      } else {
        setIsDisabledSubmit(false)
      }

      /*form.validateFields()
      .then((values)=>{
        setIsDisabledSubmit(false)
      }).catch((error) => {
        setIsDisabledSubmit(true)
      })*/
    }

    const handleFileSelect = ( event: React.ChangeEvent<HTMLInputElement> ) => {
      //Verify if the file is not null.
      if( !event.target.files )
        return;

      setSelectedFile(event.target.files[0]);
    }

    const getStandardDate = (dateString:string) => {
      const [year, month, day] = dateString.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      console.log(date);
      return date;
    }

    /**
     * Close modal window
     */
    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedFile(null);
        setFileUploadMode(false);
        dispatch(setOpen(false));
    };

    /**
     * Adds a new empty flight
     */
    const handleAddFlight = () => {
      const newFlight: Flight = {
        id: flights.length + 1,
        channel: '',
        placementName: '',
        flightStart: '',
        flightEnd: '',
        flightBudget: '',
        pacingRequirements: '',
        audience: '',
        remarketing: false,
      };
  
      setFlights([...flights, newFlight]);

      setIsDisabledSubmit(true); //a new flight was added with required fields, so disable the submit.
    };

    /**
     * When a flight field is updated, changes the list of flight with its values.
     */
    const handleFlightFieldChange = (flightId: number, fieldName: string, value: string | Date | boolean | null) => {
      setFlights((prevFlights) => {
        // Map over the previous flights array
        const updatedFlights = prevFlights.map((flight) => {
          // Check if the current flight's id matches the id of the changed flight
          if (flight.id === flightId) {
            // If matched, update the fields object with the new value for the changed field
            return {
              ...flight,
              [fieldName]: value,
            };
          }
          // If the current flight's id does not match, return it unchanged
          return flight;
        });
    
        // Return the updated flights array
        return updatedFlights;
      });
    };

    /**
     * Sets the default values when an insertionOrder is obtained and its in editMode. 
    */
   const populateFields = () => {
    form.setFieldsValue({
      advertiser: insertionOrder?.advertiser,
      poNumber: insertionOrder?.poNumber,
      totalDuration: insertionOrder?.totalDuration,
      totalBudget: insertionOrder?.totalBudget,
      //primaryContact: insertionOrder?.primaryContact,
      //billingContact: insertionOrder?.billingContact,
      clientOrBrand: insertionOrder?.clientOrBrand,
      notes: insertionOrder?.notes
    });

    if( insertionOrder?.insertionOrderFlights !== undefined ){
      const orderFlights = insertionOrder.insertionOrderFlights.map((insertionFlight, index) => {
        return {
          id: index,
          channel: insertionFlight.channel,
          placementName: insertionFlight.placementName,
          flightStart: insertionFlight.flightStart,
          flightEnd: insertionFlight.flightEnd,
          flightBudget: insertionFlight.flightBudget,
          pacingRequirements: insertionFlight.pacingRequirements,
          audience: insertionFlight.audience,
          remarketing: insertionFlight.remarketing,
        } as Flight;
      })
      console.log(orderFlights);
      setFlights( orderFlights );

      //TODO: Transform the flightStart and flightEnd to Date Objects.

      orderFlights.forEach((orderFlight) => {
        form.setFieldsValue({
          [`${orderFlight.id}_placementName`]: orderFlight.placementName,
          [`${orderFlight.id}_channel`]: orderFlight.channel,
          [`${orderFlight.id}_flightStart`]: getStandardDate(orderFlight.flightStart),
          [`${orderFlight.id}_flightEnd`]: getStandardDate(orderFlight.flightEnd),
          [`${orderFlight.id}_flightBudget`]: orderFlight.flightBudget,
          [`${orderFlight.id}_pacingRequirements`]: orderFlight.pacingRequirements,
          [`${orderFlight.id}_audience`]: orderFlight.audience,
          [`${orderFlight.id}_remarketing`]: orderFlight.remarketing,
        });
      })
      
    }
   }

    /**
     * Opens or closes the modal on state change
     */
    useEffect(() => {
      setModalOpen(isOpen);

      const isEditOrSendMode = editMode || sendMode;
      
      //Fetchs the InsertionOrder Information for edit mode only.
      if( isOpen && isEditOrSendMode && insertionOrderId != "" ){
        console.log(insertionOrderId)
        dispatch(fetchInsertionOrderDetails(insertionOrderId))
      }
    }, [isOpen, insertionOrderId]);

    useEffect(()=>{
      const isEditOrSendMode = editMode || sendMode;

      if( insertionOrder !== undefined && isEditOrSendMode ){
        console.log(insertionOrder);
        //Populate the form with the values from the existing insertionOrder.
        populateFields();
      }
    }, [insertionOrder])

    useEffect(() => {
      if(isSent){
        alert('Order was sent successfully');
        setFlights([{
          id: 1,
          channel: '',
          placementName: '',
          flightStart: '',
          flightEnd: '',
          flightBudget: '',
          pacingRequirements: '',
          audience: '',
          remarketing: false,
        }])
        form.resetFields();
        dispatch(setFormSent(false));
      }
    }, [isSent]);

    

    const defaultOptions = [
      {label: 'Google', value: 'google'},
      {label: 'Meta', value: 'facebook'}
    ]; //The baseline channels that are available for every user.

    //Adds the custom channels, if they exist, to the options once they are available.
    if (customChannels.length > 0) {
      customChannels.forEach(customChannel => {
        const newOption = { label: customChannel.title, value: customChannel.title };
        defaultOptions.push(newOption);
      });
    }

    // getting custom channels after first render
    useEffect(() => {
        dispatch(getCustomChannels());
    }, []);

    return (
        <Drawer
            visible={open}
            width="min(100vw, 700px)"
            closable={false}
        >

            <div className={`${styles["drawer-top-bar"]}  relative z-10`}>
                <IconArrowRightBlack onClick={handleModalClose}/>
            </div>
            <div className={`${styles["drawer-header"]} relative z-10`}>
                <h2>{ 
                  editMode ? "Edit insertion order"
                  : sendMode ? "Send insertion order" 
                  : ""}
                </h2>
            </div>

            <div className={`${styles["templates-header"]} mt-8 relative z-0`}>
                <div className="flex justify-center items-center h-full min-w-[80%]">
                  <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{width: "100%" }}
                    layout="vertical"
                    onChange={handleFormChange}
                  >
                    <div style={{ overflowY: 'auto', width: '100%', maxHeight: '600px' }}> 
                      
                      <Input.Group compact>
                        <Form.Item
                          label="Advertising Agency"
                          name="advertiser"
                          style={{width: "46%" }}
                          rules={[{ required: true }]}
                          tooltip={'Agency or entity that is requesting the media buy on behalf of their client'}
                        >
                          <Input disabled={sendMode} placeholder="Advertising Agency" />
                        </Form.Item>

                        <Form.Item
                          label="PO/Job number"
                          name="poNumber"
                          style={{ marginLeft: "8%", width: "46%" }}
                          rules={[{ required: true }]}
                        >
                          <Input disabled={sendMode} placeholder="PO/Job number" />
                        </Form.Item>
                      </Input.Group>

                      <Input.Group compact>
                        <Form.Item
                          label="Total duration"
                          name="totalDuration"
                          style={{width: "46%" }}
                          rules={[{ required: true }]}
                          tooltip={'Total duration covered by all flights'}
                        >
                          <Input disabled={sendMode} placeholder="Total duration" />
                        </Form.Item>

                        <Form.Item
                          label="Total budget"
                          name="totalBudget"
                          style={{ marginLeft: "8%", width: "46%" }}
                          rules={[{ required: true }]}
                        >
                          <Input disabled={sendMode} placeholder="Total budget" />
                        </Form.Item>
                      </Input.Group>

                      

                      <Form.Item
                        label="Client / Brand"
                        name="clientOrBrand"
                        style={{width: "46%" }}
                        rules={[{ required: true }]}
                        tooltip={'Company for which media will be bought'}
                      >
                        <Input disabled={sendMode} placeholder="Client or Brand" />
                      </Form.Item>

                      {flights.map((flight) => (
                        <div key={flight.id} style={{ marginTop: '10px'}}>
                          <p>Flight#{flight.id}</p>
                          <div className="grid grid-cols-2 gap-x-[7%]">
                            {Object.entries(flight).map(([fieldName, fieldValue]) => (
                              (fieldName !== 'id') && (
                                
                                (fieldName == 'channel') 
                                  ? 
                                    <Form.Item
                                      key={fieldName}
                                      name={`${flight.id}_${fieldName}`}
                                      label={flightDictionary[fieldName]}
                                      style={{ marginLeft: "0", width: "100%" }}
                                      rules={[{ required: true }]}
                                      
                                    >
                                      <Select
                                          className="custom-tag-select res:leading-[2.75rem]"
                                          placeholder="Select one channel"
                                          allowClear
                                          showArrow
                                          onChange={(value,option) =>{
                                            handleFlightFieldChange(flight.id, fieldName, value);
                                            handleFormChange();
                                          }}
                                          style={{ borderRadius: "4px", width: "100%" }}
                                          disabled={sendMode}
                                          options={defaultOptions}
                                      />
                                    </Form.Item>
                                  : (fieldName == 'remarketing') ?
                                    <Form.Item
                                      key={fieldName}
                                      name={`${flight.id}_${fieldName}`}
                                      style={{ marginLeft: "0", width: "100%" }}
                                      rules={[{ required: false }]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox onChange={(e) => handleFlightFieldChange(flight.id, fieldName, e.target.checked)}>Remarketing</Checkbox>
                                    </Form.Item>
                                  : (fieldName == 'flightStart' || fieldName == 'flightEnd') ?
                                      <Form.Item
                                        key={fieldName}
                                        label={flightDictionary[fieldName]}
                                        required={true}
                                        name={`${flight.id}_${fieldName}`}
                                        style={{ marginLeft: "0", width: "100%" }}
                                        rules={[{ required: false }]}
                                      >
                                        <DatePicker
                                          use12Hours={true}
                                          showTime={false}
                                          showHour={true}
                                          showMinute={true}
                                          showSecond={false}
                                          style={{ borderRadius: "4px", width: "100%" }}
                                          disabled={sendMode}
                                          onChange={(value,option) =>{
                                            //transform the Date to a string representation before settting it to the flight object.
                                            //This ensures the Date object is passed as a YYYY-MM-DD string
                                            //This is useful when working with different timezones.

                                            if( value ){
                                              let year = value.getFullYear();
                                              let month = (value.getMonth() + 1).toString().padStart(2, '0');
                                              let day = value.getDate().toString().padStart(2, '0');

                                              const dateString = `${year}-${month}-${day}`;

                                              handleFlightFieldChange(flight.id, fieldName, dateString);
                                              handleFormChange();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                  :
                                    <Form.Item
                                      key={fieldName}
                                      name={`${flight.id}_${fieldName}`}
                                      label={flightDictionary[fieldName]}
                                      style={{ marginLeft: "0", width: "100%" }}
                                      rules={[{ required: true }]}
                                    >
                                      <Input
                                        onChange={(e) => handleFlightFieldChange(flight.id, fieldName, e.target.value)}
                                        placeholder={flightDictionary[fieldName]}
                                        disabled={sendMode}
                                      />
                                    </Form.Item>
                              )
                            ))}
                          </div>
                        </div>
                      ))}

                      { (!sendMode && flights.length < 4) && (
                        <Form.Item style={{ marginTop: 20 }}>
                          <Button 
                            className="general-button pointer-hover flight-button"
                            onClick={handleAddFlight}
                            style={{ backgroundColor: whiteLabel.primaryColor, width: "46%" }}
                          >
                            <div className="primary-button-text">ADD FLIGHT</div>
                          </Button>
                        </Form.Item>
                      )}
                      

                      <Form.Item
                        label="Notes"
                        name="notes"
                        style={{width: "100%" }}
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Additional notes" disabled={sendMode} />
                      </Form.Item>
                      
                    </div>

                    <Form.Item style={{ marginTop: 40 }}>
                      {
                        (sendMode) ?
                          <Button
                            className="general-button pointer-hover"
                            onClick={() => {setFileUploadMode(true)}}
                            style={{ backgroundColor: whiteLabel.primaryColor, width: "100%" }}
                          >
                            <div className="primary-button-text">INVOICE</div>
                          </Button>
                        :
                          <Button
                            htmlType="submit"
                            className="general-button pointer-hover"
                            style={{ backgroundColor: isDisabledSubmit ? "rgba(0, 0, 0, 0.25)" : whiteLabel.primaryColor, width: "100%" }}
                            disabled={isDisabledSubmit}
                          >
                            <div className="primary-button-text">SUBMIT</div>
                          </Button>
                      }
                      
                    </Form.Item>

                    { orderErrors.length > 0 && (
                      <div className=" bg-red-300 text-red-950 px-4 py-1 mt-3">
                        {orderErrors.map((error) => (
                          <p>{ error }</p>
                        ))}
                      </div>
                    )}

                    

                  </Form>
                </div>

                { (sendMode && fileUploadMode) && (
                  <div className="w-full absolute inset-0 bg-white px-4 z-0">
                    <div className=" flex flex-col items-center">
                      <div>
                          {
                            (selectedFile == null) ?
                              <>
                                <input className="hidden" id={"file-uploader"} type="file" onChange={handleFileSelect} />
                                  <label htmlFor={"file-uploader"}>
                                    <div
                                        className="primary-button pointer-hover p-[8px_25px]"
                                    >
                                        <div className="primary-button-text">SELECT FILE</div>
                                    </div>
                                </label>
                              </>
                            :
                              <>
                                <div
                                    className="primary-button pointer-hover p-[8px_25px]"
                                    onClick={onSendInvoice}
                                >
                                    <div className="primary-button-text">UPLOAD</div>
                                </div>
                              </>
                          }
                          
                      </div>
                      <div className="mt-5">
                          { (selectedFile != null) && (
                            <>
                              <p className="file-upload-file-name">{selectedFile.name}</p>
                              <span onClick={() => {setSelectedFile(null)}} className="file-upload-remove-button">REMOVE</span>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
        </Drawer>
    );
}

export default OrderForm;
