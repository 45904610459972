import "./ReportsPage.scss";
import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "assets/icons/loader-onmiChannel.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserRole } from "../../models/User";
import { Location } from "../../models/Location";
import { Button, Form, Input, Select, Table, TableProps  } from "antd";
import type { ColumnsType } from 'antd/es/table';
import DatePicker from "../datepicker/DatePicker";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { selectFranchise, getGoogleAdsReports, getFacebookAdsReports, getCustomChannelReports, selectFacebookAdsReports, selectGoogleAdsReports, selectCustomChannelReports, selectGoogleAdsLoading, setGoogleAdsLoading, selectSimplifiedOmniReport, getSimplifiedOmniReports, selectCredentialValidation } from "../../store/Franchise/FranchiseStore";
import { SimplifiedOmniChart } from './OmniChannel/SimplifiedChart'
import { getCustomChannels, selectCustomChannels } from "../../store/CustomReport/CustomReportStore";
import type { FranchiseCustomReportsRow, FranchiseGadsRow, FranchiseGadsSchema, FranchiseOmniChannelRow, SystemReportDate } from "../../services/franchiseService";
import { AvailableRoutes } from "../../pages/Routes";
import { setReportsLocationId, setReportTitle, setReportCompany, selectReportCompany } from "../../store/Reports/ReportsStore";
import { addDaysToDate } from "../../utility/time";
import TendencyCard from "./OmniChannel/TendencyCard";
import { setSelectedMainMenu, setSelectedSubMenu } from "store/Navigation/NavigationStore";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { selectFranchiseLocations, selectLocations } from "store/Location/LocationStore";
import { getParamValue, updateURLParams } from "../../utility/url";

export const ReportsPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const googleAdsReports = useSelector(selectGoogleAdsReports);
    const googleAdsLoading = useSelector(selectGoogleAdsLoading);
    const customChannelReports = useSelector(selectCustomChannelReports);
    const facebookAdsReports = useSelector(selectFacebookAdsReports);
    const reportCompany = useSelector(selectReportCompany);
    const customChannels = useSelector(selectCustomChannels);
    const credentialValidation = useSelector(selectCredentialValidation);
    const [omniReportloading, setOmniReportLoading] = useState<boolean>(true);
    const [dateReportLoading, setDateReportLoading] = useState<boolean>(true);

    const locations = useSelector(selectLocations);
    const franchiseLocations = useSelector( selectFranchiseLocations )



    
    //Page controls
    const [firstPaint, setFirstPaint] = useState(true);
    const [pageG, setPageG] = useState(1);
    const [pageF, setPageF] = useState(1);
    const [pageTokens, setPageTokens] = useState([undefined] as (string | undefined)[]);
    const [customChannelCompleteReports, setCustomChannelsReports ] = useState([] as (FranchiseCustomReportsRow[]))
    const [omniChannelsReports, setOmnichannelsReports ] = useState([] as (FranchiseOmniChannelRow[]))
    const [pageTokenF, setPageTokenF] = useState(undefined as string | undefined);
    const [pageDirF, setPageDirF] = useState(undefined as string | undefined);
    const user = useSelector(selectUserAccount);
    const [isGeneratingSystem, setIsGeneratingSystem] = useState(false); //Boolean to dictate if the system report is being generated.
    const [systemReport, setSystemReport] = useState([] as SystemReportDate[]);
    const [finalGoogleAdsReports, setFinalGoogleAdsReport] = useState([] as (FranchiseGadsRow[]))

    //Get the sorted list of user locations.
    //Franchisees and Agencies use the franchiseLocations.
    //Franchisors use their own locations
    let sortedLocations : Location[] = [];
    if( user?.type === UserRole.Franchisor || user?.type === UserRole.Agency){
        sortedLocations = franchiseLocations.slice().sort((a, b) => {
            // Use localeCompare for case-insensitive sorting
            return a.name.localeCompare(b.name);
        });
    }else{
        sortedLocations = locations.slice().sort((a, b) => {
            // Use localeCompare for case-insensitive sorting
            return a.name.localeCompare(b.name);
        });
    }

    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

    //From the omni channel
    const [reportReady, setReportReady] = useState(false);
    const omniReport = useSelector(selectSimplifiedOmniReport);
    const franchise = useSelector(selectFranchise);

    const { Option } = Select;

    //  Sets the defaultValue and defaultOptions
    var defaultValue = credentialValidation.googleAds ? "Google" : "";

    if ( franchise && franchise.gadsCustomerId != "1" && franchise.fadsAccountId != "1"){
        defaultValue = credentialValidation.googleAds ? "Google" : "Facebook";
    }

    if ( franchise && franchise.gadsCustomerId != "1" && franchise.fadsAccountId == "1" && credentialValidation.googleAds ){
        defaultValue = "Google";
    }

    if ( franchise && franchise.gadsCustomerId == "1" && franchise.fadsAccountId != "1" ){
        defaultValue = "Facebook"
    }

    if ( franchise && franchise.gadsCustomerId == "1" && franchise.fadsAccountId == "1" ){
        defaultValue =  credentialValidation.googleAds ? "Google" : customChannels[0].id;
    }

    if(defaultValue == "" && credentialValidation.loaded && customChannels.length > 0){
        defaultValue = customChannels[0].id;
    }

    defaultValue = getParamValue('channel') || defaultValue;

    //temp
    function calculateTotalDates(channelReports:FranchiseOmniChannelRow[]) {
        const totalDatesMap = new Map();
        // Iterate over channelReports
        channelReports.forEach(channelReport => {
          // Iterate over locationReports
          channelReport.locationReports.forEach(locationReport => {
            // Iterate over dates
            locationReport.dates.forEach(dateObj => {
              const { date, clicks, impressions, spend } = dateObj;
      
              // Check if date already exists in the totalDatesMap
              if (totalDatesMap.has(date)) {
                const existingDate = totalDatesMap.get(date);
                // Update existing date's values
                existingDate.clicks += clicks;
                existingDate.impressions += impressions;
                existingDate.spend += spend;
                existingDate.dateCount += 1;
              } else {
                // If date doesn't exist, add it to the totalDatesMap
                totalDatesMap.set(date, {
                  date,
                  clicks,
                  impressions,
                  spend,
                  dateCount: 1
                });
              }
            });
          });
        });

        // Calculate averages, ctr, and cpc for each date
        totalDatesMap.forEach(dateObj => {
            dateObj.ctr = dateObj.clicks / dateObj.impressions;
            dateObj.cpc = dateObj.spend / dateObj.clicks;
        });
      
        // Convert Map values to array
        const totalDates = Array.from(totalDatesMap.values()) as SystemReportDate[];
      
        return totalDates;
    }

    //Listen to changes on the omniReport object.
    //This useEffect sets the load status to false when the omni report is fetched.
    useEffect(() => {
        if (omniReport.error) console.log(omniReport.error);
        if (omniReport.omniChannel != undefined) {
            setReportReady(true);
            setOmniReportLoading(false);
            console.log('report ready'); //breakpoint 1: The data from the omni channel report has been successfully obtained.
            //Once the data is obtained we begin generating the system report.
            //Start the system report generation if the user is of franchisor or agency type.
            if( user?.type === UserRole.Franchisor || user?.type === UserRole.Agency ) setIsGeneratingSystem(true);
        }
        //First we want to be able to display the report here.
        //console.log(omniReport.omniChannel);
        //Do more stuff with the object...


    }, [omniReport])

    useEffect(() => {
        if(isGeneratingSystem){
            //The system report is obtained from Sum of all the values in locationReports obtained in the omniReport.omniChannel.
            const calculatedSystemReport = calculateTotalDates( omniReport.omniChannel.channelReports );
            console.log('System has been generated successfully.');
            setIsGeneratingSystem(false);
            setSystemReport( calculatedSystemReport );
        }
    }, [isGeneratingSystem])

    useEffect(() => {
        if ( credentialValidation.loaded && defaultValue != "") {
            dispatch(setReportCompany(defaultValue));

            const currentStartDate = getParamValue('startDate');
            const currentEndDate = getParamValue('endDate');
            const currentChannel = getParamValue('channel');

            form.setFieldsValue({channel: currentChannel || defaultValue});

            if( defaultValue  === "Google"){
                form.setFieldsValue({ pageTokenG: pageTokens[pageG - 1] });
                form.setFieldsValue({ startDate: new Date(currentStartDate || googleAdsReports.lastStartDate || addDaysToDate(new Date(), -28)) });
                form.setFieldsValue({ endDate: new Date(currentEndDate || googleAdsReports.lastEndDate || new Date()) });
                form.setFieldsValue({ orderBy: googleAdsReports.lastOrderBy || 'name' });
            }else if( defaultValue === "Facebook"){
                form.setFieldsValue({ startDate: new Date(currentStartDate || facebookAdsReports.lastStartDate || addDaysToDate(new Date(), -28)) });
                form.setFieldsValue({ endDate: new Date(currentEndDate || facebookAdsReports.lastEndDate || new Date()) });
                form.setFieldsValue({ orderBy: facebookAdsReports.lastOrderBy || 'name' });
            }else{
                form.setFieldsValue({ pageTokenF: pageTokenF });
                form.setFieldsValue({ pageDir: pageDirF });
                form.setFieldsValue({ startDate: new Date(currentStartDate || customChannelReports.lastStartDate || addDaysToDate(new Date(), -28)) });
                form.setFieldsValue({ endDate: new Date(currentEndDate || customChannelReports.lastEndDate || new Date()) });
                form.setFieldsValue({ orderBy: customChannelReports.lastOrderBy || 'name' });
            }
            form.submit();
        }
    }, [credentialValidation, defaultValue]);

    /*useEffect(() => {
        if ( reportCompany == 'Google' && !firstPaint && !googleAdsLoading) {
            if (pageG < 1) {
                setPageG(1);
            } else {
                form.setFieldsValue({ pageTokenG: pageTokens[pageG - 1] });
                form.setFieldsValue({ startDate: new Date(googleAdsReports.lastStartDate || addDaysToDate(new Date(), -28)) });
                form.setFieldsValue({ endDate: new Date(googleAdsReports.lastEndDate || new Date()) });
                form.setFieldsValue({ orderBy: googleAdsReports.lastOrderBy || 'name' });
                form.submit();
            }
        }
    }, [pageG, firstPaint, googleAdsLoading, reportCompany, credentialValidation]);*/

    useEffect(() => {
        if (googleAdsReports.error) console.log(googleAdsReports.error);
        if (googleAdsReports.nextPageToken) {
            if (pageTokens.length <= pageG) {
                let pt = pageTokens.slice();
                pt.push(googleAdsReports.nextPageToken);
                setPageTokens(pt);
            } else if (pageTokens[pageG] !== googleAdsReports.nextPageToken) {
                let pt = pageTokens.slice();
                pt.splice(pageG, 1, googleAdsReports.nextPageToken);
                setPageTokens(pt);
            }
        }
        dispatch(setGoogleAdsLoading(false));
        setDateReportLoading(false);


        // console.log("google ads reports")
    }, [googleAdsReports, pageTokens]);

    /*useEffect(() => {
        if (reportCompany == 'Facebook' && (facebookAdsReports.prevPageToken || facebookAdsReports.nextPageToken)) {
            form.setFieldsValue({ pageTokenF: pageTokenF });
            form.setFieldsValue({ pageDir: pageDirF });
            form.setFieldsValue({ startDate: new Date(facebookAdsReports.lastStartDate || addDaysToDate(new Date(), -28)) });
            form.setFieldsValue({ endDate: new Date(facebookAdsReports.lastEndDate || new Date()) });
            form.setFieldsValue({ orderBy: facebookAdsReports.lastOrderBy || 'name' });
            form.submit();
        }else if(reportCompany == 'Facebook'){
            form.setFieldsValue({ startDate: new Date(facebookAdsReports.lastStartDate || addDaysToDate(new Date(), -28)) });
            form.setFieldsValue({ endDate: new Date(facebookAdsReports.lastEndDate || new Date()) });
            form.setFieldsValue({ orderBy: facebookAdsReports.lastOrderBy || 'name' });
            form.submit();
        }
    }, [pageF, pageTokenF, pageDirF, reportCompany]);*/

    useEffect(() => {
        if (facebookAdsReports.error) console.log("Facebook error: ",facebookAdsReports.error);
        setDateReportLoading(false);
        //TODO pages control
    }, [facebookAdsReports]);

    /*useEffect(() => {        
        if (reportCompany != "" && reportCompany != 'Facebook' && reportCompany != 'Google') {
            form.setFieldsValue({ pageTokenF: pageTokenF });
            form.setFieldsValue({ pageDir: pageDirF });
            form.setFieldsValue({ startDate: new Date(customChannelReports.lastStartDate || addDaysToDate(new Date(), -28)) });
            form.setFieldsValue({ endDate: new Date(customChannelReports.lastEndDate || new Date()) });
            form.setFieldsValue({ orderBy: customChannelReports.lastOrderBy || 'name' });
            form.submit();
        }
    }, [reportCompany, credentialValidation]);*/


    useEffect( () => {
        let customReportChannelsComplete: FranchiseCustomReportsRow[] = []
        if(customChannelReports.channels != undefined && customChannelReports.channels.length > 0){
            for(const _channel of customChannelReports.channels   ) {
                const updatedChannel = {
                    ..._channel,
                    cpc: _channel.cost / _channel.clicks,
                    ctr: (_channel.clicks / _channel.impressions)*100
                };
                customReportChannelsComplete.push(updatedChannel)
            }
        }

        setCustomChannelsReports(customReportChannelsComplete)
        setDateReportLoading(false);

    }, [customChannelReports]  )

    useEffect(() => {
        dispatch(setGoogleAdsLoading(true));
        dispatch(getCustomChannels());
        //dispatch(getGoogleAdsReports({}));
        //dispatch(getFacebookAdsReports({}));
        //dispatch(getCustomChannelReports({}));
        dispatch(setSelectedMainMenu("report") );
        dispatch(setSelectedSubMenu("Active campaigns"));
        form.setFieldsValue({
            orderBy: 'name',
            dateRange: getParamValue('dateRange') || 'fourteen',
            startDate: getParamValue('startDate') || null,
            endDate: getParamValue('endDate') || null
        });
        setFirstPaint(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const [form] = Form.useForm();

    //breakpoint A
    const onFinish = (e: any) => {
        const userId = getParamValue('user_id') || undefined;
        if( reportCompany  === "Google"){
            dispatch(getGoogleAdsReports({ dateRange: e.dateRange, startDate: e.startDate, endDate: e.endDate, orderBy: e.orderBy, pageToken: e.pageTokenG, locations: e.locations, userId }));
        }else if( reportCompany === "Facebook"){
            dispatch(getFacebookAdsReports({ dateRange: e.dateRange, startDate: e.startDate, endDate: e.endDate, orderBy: e.orderBy, pageToken: e.pageTokenF, pageDir: e.pageDir, locations: e.locations, userId }));
        }else{
            dispatch(getCustomChannelReports({ dateRange: e.dateRange, startDate: e.startDate, endDate: e.endDate, orderBy: e.orderBy, channelId: reportCompany, locations: e.locations, userId}));
        }
        
        setOmniReportLoading(true);
        setDateReportLoading(true);
        dispatch(getSimplifiedOmniReports({
            channelName: reportCompany.toLowerCase(),
            dateRange: e.dateRange,
            startDate: e.startDate,
            endDate: e.endDate,
            userId: userId
        }));

    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    }

    const onOrderChange = (value: string) => {
        form.setFieldsValue({ orderBy: value });
        handleChannelChange();
    };

    const columns: ColumnsType<FranchiseGadsRow> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (val => `${val.toLocaleString('en-US')}`)
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            render: (val => `${((isNaN(val) ? 0 : val) * 100).toFixed(2)}%`)
        },
        {
            title: 'CPC',
            dataIndex: 'cpc',
            key: 'cpc',
            render: (val => `$${((isNaN(val) ? 0 : val) / 1000000).toFixed(2)}`)
        },
        {
            title: 'Spend',
            dataIndex: 'cost',
            key: 'cost',
            render: (val => `$${((isNaN(val) ? 0 : val) / 1000000).toLocaleString('en-US',{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`)
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            render: (val => `${val.toLocaleString('en-US')}`)
        },
        {
            title: 'Conversions',
            dataIndex: 'conversions',
            key: 'conversions',
            render: (val => val === 0 ? '--' : `${Number(val.toFixed(2)).toLocaleString('en-US')}`)
        }
        // Will re-enable with the individual report page updates
        // {
        //     title: '',
        //     dataIndex: 'actions',
        //     key: 'actions',
        //     render: (_, record) => {
        //         return (
        //             <Button className="m-0 report-apply-button pointer-hover" onClick={() => {
        //                 dispatch(setReportsLocationId(record.campaignId));
        //                 dispatch(setReportTitle('Impressions & clicks'));
        //                 navigate(AvailableRoutes.REPORT);
        //             }}>
        //                 View
        //             </Button>
        //         )
        //     }
        // }
    ];

    const customColumns: ColumnsType<FranchiseCustomReportsRow> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Impressions ',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (val => `${val.toLocaleString('en-US')}`)
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            render: (val => `${val.toFixed(2)}%`),
        },
        {
            title: 'CPC',
            dataIndex: 'cpc',
            key: 'cpc',
            render: (val => `$${val.toFixed(2)}`),
        },
        {
            title: 'Spend',
            dataIndex: 'cost',
            key: 'cost',
            render: (val => `$${val.toLocaleString('en-US',{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`)
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            render: (val => `${val.toLocaleString('en-US')}`)
        },
        {
            title: 'Conversions',
            dataIndex: 'conversions',
            key: 'conversions',
            render: (val => val === 0 ? '--' : `${Number(val.toFixed(2)).toLocaleString('en-US')}`)
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => {
                return (
                    <div></div>
                )
            }
        }
    ];

    // dataSource for the Google and Meta table
    let dataSourceGoogleMeta = reportCompany == 'Google' ?
            selectedLocations.length > 0 ?
                googleAdsReports.campaigns?.filter( row => { return selectedLocations.includes(row.key) == true } )
            : googleAdsReports?.campaigns
            
        : 
            selectedLocations.length > 0 ? 
                facebookAdsReports.campaigns?.filter( row => { return selectedLocations.includes(row.key) == true } )
            : facebookAdsReports?.campaigns
    ;

    if(dataSourceGoogleMeta != undefined){
        dataSourceGoogleMeta = dataSourceGoogleMeta.map((item, index) => ({
            ...item,
            key: `${item.key}-${index}`
        }))
    }

    // dataSource for the custom Channels
    let dataSourceCustom = selectedLocations.length > 0 ?
            customChannelCompleteReports?.filter( row => { return row.channelId == reportCompany.toLocaleLowerCase() && selectedLocations.includes(row.key) == true } ) 
        : customChannelCompleteReports?.filter( row => { return row.channelId == reportCompany.toLocaleLowerCase() } )
    ;

    if(dataSourceCustom != undefined){
        dataSourceCustom = dataSourceCustom.map((item, index) => ({
            ...item,
            key: `${item.key}-${index}`
        }))
    }

    const staticChannels = [];

    if( credentialValidation.meta ){
        staticChannels.push({ label: 'Meta', value: 'Facebook' });
    }

    if( credentialValidation.googleAds ){
        staticChannels.push({ label: 'Google', value: 'Google' });
    }

    const customChannel = customChannels.map((channel, i) => ({
        label: channel.title, // Ajusta esto según la estructura de tus canales personalizados
        value: channel.id, // Ajusta esto según la estructura de tus canales personalizados
    }));
    const opcionesTotales = [...staticChannels, ...customChannel];
    //const opcionesTotales = [...customChannel];
    const channelSelectChanged = (e: any) => {
        dispatch(setReportCompany(e));
        handleChannelChange();
        updateURLParams({channel: e as string});
    }

      //Listeners
    const onDateRangeChange = (value: string) => {
        form.setFieldsValue({ dateRange: value });
        setOmniReportLoading(true);
        setDateReportLoading(true);
        form.submit();
        updateURLParams({dateRange: value as string});
    }

    const handleChannelChange = ( ) => {
        setOmniReportLoading(true);
        setDateReportLoading(true);
        form.submit();
    };

    const onCustomStartDateChange = (e: any) =>{
        setOmniReportLoading(true);
        setDateReportLoading(true);
        form.submit();
        updateURLParams({startDate: e as string});
    }
    const onCustomEndDateChange = (e: any) =>{
        setOmniReportLoading(true);
        setDateReportLoading(true);
        form.submit();
        updateURLParams({endDate: e as string})
    }

    const onLocatiosnChange = ( value: string[] ) => {
        setDateReportLoading(true);
        form.submit();
        setSelectedLocations(value);
    }

    console.log(dataSourceGoogleMeta);

    return (
        <div className="reports-page-container res:mb-[180px]">
            <div>
                <div className="report-header">

                    <div className="report-title">{"Active campaigns"}</div>

                </div>

                {
                    (user?.type === UserRole.Franchisor || user?.type === UserRole.Agency || user?.type === UserRole.Franchisee)
                    ?
                        <div className="grid items-start grid-cols-3 res:grid-cols-1">
                            <div className="flex flex-wrap items-center w-full col-span-1">
                                <Form
                                    form={form}
                                    name="basic"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    
                                    autoComplete="off"
                                    className="container-form"
                                    layout="vertical"
                                >
                                    <Form.Item
                                        hidden
                                        name='pageTokenG'
                                        className="res:h-0 res:m-0"
                                    />
                                    <Form.Item
                                        hidden
                                        name='pageTokenF'
                                        className="res:h-0 res:m-0"
                                    />
                                    <Form.Item
                                        hidden
                                        name='pageDir'
                                        className="res:h-0 res:m-0"
                                    />
                                    <div className="report-form-container">
                                        <Input.Group compact className="!grid grid-cols-2 !gap-x-3 before:!hidden">
                                            <Form.Item
                                                label="Channel"
                                                className="res:!w-full !ml-0"
                                                name="channel"
                                                style={{  width: "140px" }}
                                                
                                                >
                                                <Select
                                                    showArrow
                                                    style={{ borderRadius: "4px", width: "100%" }}
                                                    defaultValue={reportCompany}
                                                    onChange={ channelSelectChanged }
                                                    className="mb-[1px]"
                                                    options={opcionesTotales}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="Range"
                                                name="dateRange"
                                                className="res:!w-full"
                                                style={{ width: "140px" }}
                                            >
                                                <Select
                                                    showArrow
                                                    
                                                    style={{ borderRadius: "4px", width: "100%" }}
                                                    onChange={onDateRangeChange}
                                                    options={[
                                                        { label: 'Last 14 days', value: 'fourteen' },
                                                        { label: 'Month to date', value: 'mtd' },
                                                        { label: 'Year to date', value: 'ytd' },
                                                        { label: 'Custom', value: 'custom' },
                                                    ]}
                                                />
                                            </Form.Item>

                                            { (form.getFieldValue("dateRange") == "custom") && (
                                                <>
                                                    <Form.Item
                                                        label="From"
                                                        className="res:!w-full"
                                                        name="startDate"
                                                        style={{ width: "140px"}}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <DatePicker
                                                            use12Hours={true}
                                                            className="res:py-3"
                                                            onChange={onCustomStartDateChange}
                                                            showTime={false}
                                                            showHour={true}
                                                            showMinute={true}
                                                            showSecond={false}
                                                            style={{ borderRadius: "4px", width: "100%" }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="To"
                                                        className="res:!w-full res:!ml-0"
                                                        name="endDate"
                                                        style={{ width: "140px" }}
                                                    >
                                                        <DatePicker
                                                            use12Hours={true}
                                                            className="res:py-3"
                                                            showTime={false}
                                                            showHour={true}
                                                            showMinute={true}
                                                            showSecond={false}
                                                            onChange={onCustomEndDateChange}
                                                            style={{ borderRadius: "4px", width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </>
                                            )}

                                            { (user?.type !== UserRole.Franchisee) && (
                                                <Form.Item
                                                    label="Locations"
                                                    className="res:!w-full !ml-0"
                                                    name="locations"
                                                    style={{ width: "140px" }}
                                                >
                                                    <Select
                                                        className="multipleSelectCustomStyle"
                                                        mode="multiple"
                                                        style={{ borderRadius: "4px", width: "100%" }}
                                                        showSearch={false}
                                                        placeholder="Select a location"
                                                        maxTagCount={1}
                                                        onChange={onLocatiosnChange}

                                                    >
                                                        {sortedLocations.map(location => (
                                                            <Option
                                                                disabled={
                                                                    selectedLocations.length > 4 ?
                                                                        selectedLocations.includes(location.id) ?
                                                                        false
                                                                        : true
                                                                    : false
                                                                }
                                                                key={location.id}
                                                            >
                                                                {location.name}
                                                            </Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            )}

                                            <Form.Item
                                                label="Sort by"
                                                className="res:!w-full !ml-0"
                                                name="orderBy"
                                                style={{ width: "140px" }}
                                            >
                                                <Select
                                                    showArrow
                                                    style={{ borderRadius: "4px", width: "100%" }}
                                                    defaultValue={'name'}
                                                    onChange={onOrderChange}
                                                    options={[
                                                        { label: 'Name', value: 'name' },
                                                        { label: 'Impressions', value: 'impressions' },
                                                        // { label: 'CTR', value: 'ctr' },
                                                        // { label: 'CPC', value: 'cpc' },
                                                        { label: 'Spend', value: 'cost' }
                                                    ]}
                                                />
                                            </Form.Item>
                                            
                                        </Input.Group>
                                    </div>
                                </Form>
                            </div>

                            <div className={"py-0 h-auto res:order-3 res:max-w-[97vw] res:overflow-x-scroll col-span-2 "}>
                                {
                                    (reportReady && omniReport.omniChannel.channelReports.length > 0 && selectedLocations.length > 0) &&
                                    <SimplifiedOmniChart 
                                        selectedChannel={reportCompany.toLocaleLowerCase()} 
                                        customChannelOptions={opcionesTotales} 
                                        defaultChannel={omniReport.omniChannel.channelReports[0].name} 
                                        channelReports={omniReport.omniChannel.channelReports}  
                                        selectedLocations={selectedLocations}
                                        isGeneratingSystemReport={isGeneratingSystem}
                                        systemReportDates={systemReport}  
                                    />
                                }
                            </div>
                        </div>

                    :
                        <div className="flex flex-wrap items-center">
                            <Form
                                form={form}
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                className="container-form"
                                layout="vertical"
                            >
                                <div className="report-form-container">
                                    <Input.Group compact className="res:!grid res:grid-cols-2 res:gap-x-3 res:items-end">
                                        <Form.Item
                                            hidden
                                            name='pageTokenG'
                                            className="res:h-0 res:m-0"
                                        />
                                        <Form.Item
                                            hidden
                                            name='pageTokenF'
                                            className="res:h-0 res:m-0"
                                        />
                                        <Form.Item
                                            hidden
                                            name='pageDir'
                                            className="res:h-0 res:m-0"
                                        />
                                        <Form.Item
                                            label="Channel"
                                            name="channel"
                                            style={{  width: "130px" }}
                                            
                                        >
                                            <Select
                                                showArrow
                                                style={{ borderRadius: "4px", width: "100%" }}
                                                defaultValue={reportCompany}
                                                onChange={ channelSelectChanged }
                                                className="mb-[1px]"
                                                options={opcionesTotales}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="From"
                                            className="res:!w-full"
                                            name="startDate"
                                            style={{ width: "140px", marginLeft: "16px" }}
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker
                                                use12Hours={true}
                                                className="res:py-3"
                                                showTime={false}
                                                showHour={true}
                                                showMinute={true}
                                                showSecond={false}
                                                style={{ borderRadius: "4px", width: "100%" }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="To"
                                            className="res:!w-full res:!ml-0"
                                            name="endDate"
                                            style={{ marginLeft: "16px", width: "140px" }}
                                        >
                                            <DatePicker
                                                use12Hours={true}
                                                className="res:py-3"
                                                showTime={false}
                                                showHour={true}
                                                showMinute={true}
                                                showSecond={false}
                                                style={{ borderRadius: "4px", width: "100%" }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Sort by"
                                            className="res:!w-full res:!ml-0"
                                            name="orderBy"
                                            style={{ marginLeft: "16px", width: "140px" }}
                                        >
                                            <Select
                                                showArrow
                                                style={{ borderRadius: "4px", width: "100%" }}
                                                defaultValue={'name'}
                                                onChange={onOrderChange}
                                                options={[
                                                    { label: 'Name', value: 'name' },
                                                    { label: 'Impressions', value: 'impressions' },
                                                    // { label: 'CTR', value: 'ctr' },
                                                    // { label: 'CPC', value: 'cpc' },
                                                    { label: 'Spend', value: 'cost' }
                                                ]}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                htmlType="submit"
                                                className="report-apply-button pointer-hover res:m-0 res:!w-full"
                                                onClick={() => {
                                                    setPageG(1);
                                                    setPageTokens([undefined]);
                                                }}
                                            >
                                                APPLY
                                                <IconArrowRight />
                                            </Button>
                                        </Form.Item>
                                    </Input.Group>
                                </div>
                            </Form>
                        </div>
                }
                
                <div className="mt-4 report-body">
                    {reportCompany == 'Google' ?
                        pageTokens.length > 1 ?
                            <div className="pagination-container">
                                <ul>
                                    <li key='1'>
                                        <Button type="text" className="prev" disabled={pageG <= 1} onClick={() => setPageG(pageG - 1)}>
                                        </Button>
                                    </li>
                                    <li key='2'>
                                        <span>{pageG}</span>
                                    </li>
                                    <li key='3'>
                                        <Button type="text" className="next" disabled={!pageTokens[pageG]} onClick={() => setPageG(pageG + 1)}>
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                            : null
                        :reportCompany == 'Facebook' ?
                            facebookAdsReports.hasPrevPage || facebookAdsReports.hasNextPage ?
                                <div className="pagination-container">
                                    <ul>
                                        <li key='1'>
                                            <Button type="text" className="prev" disabled={!facebookAdsReports.hasPrevPage} onClick={() => {
                                                setPageF(pageF - 1);
                                                setPageTokenF(facebookAdsReports.prevPageToken);
                                                setPageDirF('before');
                                            }}>
                                            </Button>
                                        </li>
                                        <li key='2'>
                                            <span>{pageF}</span>
                                        </li>
                                        <li key='3'>
                                            <Button type="text" className="next" disabled={!facebookAdsReports.hasNextPage} onClick={() => {
                                                setPageF(pageF + 1);
                                                setPageTokenF(facebookAdsReports.nextPageToken);
                                                setPageDirF('after');
                                            }}>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            : null
                        : null
                    }
                    { 
                        reportCompany == 'Facebook' || reportCompany == 'Google' ?
                            <Table className="res:overflow-x-scroll report-alltable"
                                columns={columns} 
                                pagination={false}  
                                dataSource={dataSourceGoogleMeta}
                                />
                        : (reportCompany != "" && customChannelReports.channels != undefined ) ?
                            <Table className="res:overflow-x-scroll report-alltable "
                                columns={customColumns}
                                pagination={false}
                                dataSource={dataSourceCustom}
                            >

                            </Table>
                        :
                        <></>
                    }
                    
                </div>
            </div>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={omniReportloading || dateReportLoading}
            >
                <LoaderIcon className="loader-icon" />
                {/* <CircularProgress color="inherit" /> */}
            </Backdrop>
        </div>
    );
};
